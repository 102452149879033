import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { CompanyDTO, CompanyListDTO } from "../models/companies";
import { CompaniesService } from "./companies.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class CompaniesDataSource implements DataSource<CompanyDTO>{

    private dataSubject = new BehaviorSubject<CompanyDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private companiesService: CompaniesService,
        private snackBar: MatSnackBar) { }

    loadData(pageSize: number,
        head: number, name: string) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.companiesService.getList(pageSize, head, name).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as CompanyListDTO
                this.count = listDTO.totalCompanies ? listDTO.totalCompanies : 0;
                this.dataSubject.next(listDTO.companies ? listDTO.companies : []);
            },
            err => this.snackBar.open("Error retrieving companies from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<CompanyDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
}
