<app-log-in *ngIf="(isLoggedIn$ | async) == false"></app-log-in>

<div
  class="app-container"
  *ngIf="isLoggedIn$ | async"
  [class.is-mobile]="mobileQuery.matches"
>
  <mat-toolbar color="primary" class="toolbar">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="app-name">Seedorina Backoffice</h1>
  </mat-toolbar>

  <mat-sidenav-container
    class="sidenav-container"
    [style.marginTop.px]="mobileQuery.matches ? 56 : 0"
  >
    <mat-sidenav
      #snav
      [mode]="mobileQuery.matches ? 'over' : 'side'"
      [fixedInViewport]="mobileQuery.matches"
      fixedTopGap="56"
    >
      <mat-nav-list>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/companies']"
        >
          <mat-icon aria-hidden="false">business</mat-icon>
          <span>Companies</span>
        </a>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/robots']"
        >
          <mat-icon aria-hidden="false">developer_board</mat-icon>
          <span>Robots</span>
        </a>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/programs']"
        >
          <mat-icon aria-hidden="false">code</mat-icon>
          <span>Programs</span>
        </a>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/seeds']"
        >
          <mat-icon aria-hidden="false">spa</mat-icon>
          <span>Seeds</span>
        </a>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/products']"
        >
          <mat-icon aria-hidden="false">inventory_2</mat-icon>
          <span>Products</span>
        </a>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/vendors']"
        >
          <mat-icon aria-hidden="false">store</mat-icon>
          <span>Merchant</span>
        </a>
        <a
          mat-list-item
          [routerLinkActive]="['active']"
          [routerLink]="['/orders']"
        >
          <mat-icon aria-hidden="false">point_of_sale</mat-icon>
          <span>Sales</span>
        </a>
        <a mat-list-item [routerLinkActive]="['active']" (click)="onLogout()">
          <mat-icon aria-hidden="false">exit_to_app</mat-icon>
          <span>Logout</span>
        </a>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <!-- progress-bar -->
      <mat-progress-bar
        *ngIf="isLoading$ | async"
        mode="indeterminate"
        color="warn"
      >
      </mat-progress-bar>

      <!-- <mat-progress-bar
        *ngIf="(isLoading$ | async) && !(wheelLoader | async)"
        mode="indeterminate"
        color="primary"
      >
      </mat-progress-bar> -->
      <!-- <p>hola {{ isLoading$ | async }}</p> -->
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
