import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { ProductDTO, ProductListDTO } from "../models/products";
import { ProductsService } from "./products.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class ProductsDataSource implements DataSource<ProductDTO>{

    private dataSubject = new BehaviorSubject<ProductDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private productsService: ProductsService,
        private snackBar: MatSnackBar) { }

    loadData(pageSize: number,
        head: number, name: string, enabled: boolean, vendorId: number, categoryId: number, subCategoryId: number) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.productsService.getList(pageSize, head, name, enabled, vendorId, categoryId, subCategoryId).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as ProductListDTO
                this.count = listDTO.totalProducts ? listDTO.totalProducts : 0;
                this.dataSubject.next(listDTO.products ? listDTO.products : []);
            },
            err => this.snackBar.open("Error retrieving products from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<ProductDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
}