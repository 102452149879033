import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CompanyDTO } from 'src/app/models/companies';
import { CompaniesService } from 'src/app/services/companies.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { RobotCompany, RobotDTO } from 'src/app/models/robots';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.scss'],
})
export class CompanyDetailsComponent implements OnInit {
  /* Form */
  company?: CompanyDTO;
  robots?: RobotDTO[];
  profileForm!: FormGroup;

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;

  // Convenience getter for easy access to form fields
  get f() {
    return this.profileForm.controls;
  }
  get robotsFormArray(): FormArray {
    return this.profileForm.get('robots') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private companiesService: CompaniesService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    private utilDetailService: UtilDetailService
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.company = this.route.snapshot.data.company as CompanyDTO;
    this.robots = this.route.snapshot.data.robots as RobotDTO[];
    this.initializeForm();
  }

  initializeForm() {
    this.profileForm = this.fb.group({
      id: [this.company ? this.company.companyId : null],
      name: [this.company ? this.company.name : null, Validators.required],
      createdAt: [
        this.company
          ? {
              value: this.company.createdAt?.toLocaleString().substring(0, 16),
              disabled: true,
            }
          : null,
      ],
      robots: this.fb.array([]),
    });

    this.company?.robotCompanies?.forEach((robotCompany) => {
      this.addRobot(robotCompany);
    });
  }

  public addRobot(robotCompany: RobotCompany | undefined) {
    // Get RobotDTO from RobotCompany
    let robot;
    this.robots?.forEach((robotDTO) => {
      if (robotCompany && robotDTO.robotId == robotCompany?.robotId)
        robot = robotDTO;
    });

    const formGroup: FormGroup = this.fb.group({
      id: [robotCompany ? robotCompany.id : null],
      robotId: [robotCompany ? robotCompany.robotId : null],
      serial: [robot ? robot : null],
      token: [robotCompany ? robotCompany.token : null],
      fromDate: [
        robotCompany ? robotCompany.fromDate : this.today,
        Validators.required,
      ],
      toDate: [
        robotCompany ? robotCompany.toDate : this.today,
        Validators.required,
      ],
      enabled: [
        robotCompany ? robotCompany.enabled : true,
        Validators.required,
      ],
    });

    this.robotsFormArray.push(formGroup);
  }

  public deleteRobot(form: AbstractControl) {
    for (let index = 0; index < this.robotsFormArray.length; index++) {
      const abstractControl: AbstractControl = this.robotsFormArray.at(index);
      if (abstractControl == form) {
        this.robotsFormArray.removeAt(index);
      }
    }
  }

  public isRobotListEmpty(): boolean {
    if (this.robotsFormArray.length > 0) return false;
    else return true;
  }

  // Create or Update
  public onSubmit() {
    this.isLoadingService.add();
    let isModelNew = true;

    // Create companies robots list
    let robotCompanyList: RobotCompany[] = [];
    for (let index = 0; index < this.robotsFormArray.length; index++) {
      const abstractControl: AbstractControl = this.robotsFormArray.at(index);

      // Check if user input either a serial or a token newly associated Robots 
      if (!abstractControl.value.id && !abstractControl.value.serial.robotId && !abstractControl.value.token ) {
        if (abstractControl.value.serial && !abstractControl.value.serial.robotId) {
          this.snackBar.open(
            'You must select Robot serial number/s from the autocomplete list.'
          );
          
          (abstractControl as FormControl).get("serial")?.setValue("");
        } else
          this.snackBar.open(
            'Either Serial number or Token needs to be entered for all Robots.'
          );

        this.isLoadingService.remove();
        return;
      }

      let robotCompany: RobotCompany = {
        enabled: abstractControl.value.enabled,
        fromDate: abstractControl.value.fromDate,
        toDate: abstractControl.value.toDate,
      };

      if (abstractControl.value.id) {
        robotCompany.id = abstractControl.value.id;
        isModelNew = false;
      }

      if (abstractControl.value.token)
        robotCompany.token = abstractControl.value.token;

      if (abstractControl.value.serial)
        robotCompany.robotId = abstractControl.value.serial.robotId;

      robotCompanyList.push(robotCompany);
    }

    // Create Company DTO
    let companyDTO: CompanyDTO = {
      name: this.profileForm.value.name,
      robotCompanies: robotCompanyList,
    };
    if (this.company) {
      companyDTO.companyId = this.profileForm.value.id;
      isModelNew = false;
    }

    // Create or Update
    this.companiesService.modify(companyDTO).subscribe(
      async (result: CompanyDTO) => {
        if (result != null && result.companyId) {
          this.company = result;
          this.location.replaceState('/company?Id=' + this.company.companyId);
          this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Company');
          this.location.back();
        } else {
          this.snackBar.open('Error creating or updating Company.', 'Ok');
          this.company = undefined;
        }
        this.isLoadingService.remove();
      },
      (err) => {}
    );
  }

  backClicked() {
    this.location.back();
  }
}
