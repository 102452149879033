import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { RobotDTO } from 'src/app/models/robots';
import { RobotsService } from 'src/app/services/robots.service';

@Component({
  selector: 'app-robot-select',
  templateUrl: './robot-select.component.html',
  styleUrls: ['./robot-select.component.scss']
})
export class RobotSelectComponent implements OnInit {

  /* Inputs */
  @Input()
  robotFormGroup?: AbstractControl;

  /* Form */
  robot?: RobotDTO;
  robots?: RobotDTO[];
  filteredRobots: Observable<RobotDTO[] | undefined> | undefined;
  //isLoadingRobot?: boolean;

  /* Outputs */
  @Output() deleteRobotSelect = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private robotsService: RobotsService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.initializeRobotAutocomplete();
  }

  getFormGroup(): FormGroup {
    return this.robotFormGroup as FormGroup
  }

  isRobotSelectNew(): boolean {
    return ((this.robotFormGroup as FormGroup).controls.id.value) ? false : true
  }

  private async initializeRobotAutocomplete() {
    this.serialValueChangeHandler();
    this.getRobots();
  }

  private getRobots() {
    this.robotsService.getListAutocomplet().toPromise().then(result => {
      if (result) {
        this.robots = result;
        //this.isLoadingRobot = false;
      } else { this.snackBar.open('Error fetching robots from server.', 'Ok'); }
    });
  }

  private serialValueChangeHandler() {
    this.filteredRobots = this.getFormGroup().controls.serial.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.serial),
        map(serial => serial ? this._filterRobots(serial) : this.robots?.slice())
      );
  }

  displayRobotName(robot?: RobotDTO): string {
    return robot ? robot.serial : "";
  }

  private _filterRobots(value: string): RobotDTO[] {
    const filterValue = value.toLowerCase();

    if (this.robots)
      return this.robots.filter(option => option.serial.toLowerCase().indexOf(filterValue) === 0);
    else return []
  }

  deleteRobot() {
    this.deleteRobotSelect.emit();
  }
}
