<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Companies
        </button>
      </div>
      <div>
        <h1 *ngIf="!company" class="mat-headline">New Company</h1>
        <h1 *ngIf="company" class="mat-headline">Update Company</h1>
      </div>
    </div>
  </section>

  <form *ngIf="profileForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <!-- Product Info -->
    <mat-card class="card">
      <mat-card-content>
        <div class="card-content-container">
          <!-- Name -->
          <mat-form-field>
            <input matInput placeholder="Name" formControlName="name" />
            <mat-error *ngIf="f.name.errors && f.name.errors.required">
              Name is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>

          <!-- CreatedAt -->
          <mat-form-field *ngIf="company">
            <mat-label>Created</mat-label>
            <input matInput formControlName="createdAt" type="datetime-local" />
          </mat-form-field>
        </div>
      </mat-card-content>
    </mat-card>

    <div
      [ngClass]="
        isRobotListEmpty() ? 'card-over-container' : 'card-title-over-container'
      "
    >
      <h2 *ngIf="!isRobotListEmpty()" class="mat-headline">Robots</h2>
      <button
        type="button"
        mat-stroked-button
        color="primary"
        (click)="addRobot(undefined); $event.preventDefault()"
      >
        Add Robot
      </button>
    </div>
    <app-robot-select
      formArrayName="robots"
      *ngFor="let robot of robotsFormArray.controls; let i = index"
      [robotFormGroup]="robot"
      (deleteRobotSelect)="deleteRobot(robot)"
    ></app-robot-select>

    <!-- Submit Button -->
    <div class="submit-button">
      <button
        type="submit"
        style="width: 100%"
        [disabled]="!profileForm.valid || (isLoading$ | async)"
        mat-raised-button
        color="accent"
        class="md-button-right"
      >
        <div *ngIf="!company">Create</div>
        <div *ngIf="company">Update</div>
      </button>
    </div>
  </form>
</div>
