import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { RobotDTO } from 'src/app/models/robots';
import { RobotsService } from 'src/app/services/robots.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';

@Component({
  selector: 'app-robot-details',
  templateUrl: './robot-details.component.html',
  styleUrls: ['./robot-details.component.scss']
})
export class RobotDetailsComponent implements OnInit {

  /* Form */
  robot?: RobotDTO;
  profileForm!: FormGroup;

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;

  get f() { return this.profileForm.controls; }

  constructor(private fb: FormBuilder,
    private robotsService: RobotsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    private utilDetailService: UtilDetailService) { }

  ngOnInit(): void {
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.robot = this.route.snapshot.data.robot as RobotDTO;
    this.initializeForm();
  }

  initializeForm() {
    let createdAt = new Date((this.robot && this.robot.createdAt) ? this.robot.createdAt.toString().substring(0, 16) + "Z" : "");
    let updatedAt = new Date((this.robot && this.robot.updatedAt) ? this.robot.updatedAt.toString().substring(0, 16) + "Z" : "");

    this.profileForm = this.fb.group({
      id: [this.robot ? this.robot.robotId : null],
      serial: [this.robot ? { value: this.robot.serial, disabled: true } : null, Validators.required],
      createdAt: [(this.robot && createdAt) ? { value: this.toDateString(createdAt), disabled: true } : null],
      updatedAt: [(this.robot && updatedAt) ? { value: this.toDateString(updatedAt), disabled: true } : null],
      enabled: [this.robot ? this.robot.enabled : true, Validators.required],
      firmware: [this.robot ? this.robot.firmware : null, Validators.required],
      relativeZ: [this.robot ? this.robot.relativeZ : null, Validators.required],
      tOFHeight: [this.robot ? this.robot.tofHeight : null, Validators.required],
      store1: [this.robot ? this.robot.store1 : null, Validators.required],
      store2: [this.robot ? this.robot.store2 : null, Validators.required],
      store3: [this.robot ? this.robot.store3 : null, Validators.required],
      storeBrush: [this.robot ? this.robot.storeBrush : null, Validators.required],
    });
  }

  toDateString(date: Date): string {
    return (date.getFullYear().toString() + '-'
      + ("0" + (date.getMonth() + 1)).slice(-2) + '-'
      + ("0" + (date.getDate())).slice(-2))
      + 'T' + date.toTimeString().slice(0, 5);
  }

  // Create or Update Product
  public onSubmit() {
    this.isLoadingService.add();
    let isModelNew = true;

    // Create DTO
    let robotDTO: RobotDTO = {
      serial: this.profileForm.value.serial,
      enabled: this.profileForm.value.enabled,
      firmware: this.profileForm.value.firmware,
      relativeZ: this.profileForm.value.relativeZ,
      store1: this.profileForm.value.store1,
      store2: this.profileForm.value.store2,
      store3: this.profileForm.value.store3,
      storeBrush: this.profileForm.value.storeBrush,
    }
    if (this.robot) {
      robotDTO.robotId = this.profileForm.value.id
      isModelNew = false
    }

    // Create or Update Robot
    this.robotsService.modify(robotDTO).subscribe(async (result: RobotDTO) => {
      if (result != null && result.robotId) {
        this.robot = result;
        this.location.replaceState('/robot?Id=' + this.robot.robotId);
        this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Robot');
        this.location.back()
      } else {
        this.snackBar.open('Error creating or updating Robot.', 'Ok');
        this.robot = undefined;
      }
      this.isLoadingService.remove();
    });
  }

  backClicked() {
    this.location.back();
  }
}
