<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Robots
        </button>
      </div>
      <div>
        <h1 *ngIf="!robot" class="mat-headline">New Robot</h1>
        <h1 *ngIf="robot" class="mat-headline">Update Robot</h1>
      </div>
    </div>
  </section>

  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="cards-container">
      <!-- Product Info -->
      <mat-card class="card">
        <mat-card-content>
          <div class="card-content-container">
            <!-- Serial -->
            <mat-form-field class="serial">
              <input matInput placeholder="Serial" formControlName="serial" />
              <mat-error *ngIf="f.serial.errors && f.serial.errors.required">
                Serial number is <strong>mandatory</strong>
              </mat-error>
            </mat-form-field>

            <!-- Token -->
            <!-- <mat-form-field class="token">
              <input matInput placeholder="Token" formControlName="token" />
              <mat-error *ngIf="f.token.errors && f.token.errors.required">
                El token es un campo <strong>requerido</strong>
              </mat-error>
            </mat-form-field> -->

            <!-- CreatedAt -->
            <mat-form-field *ngIf="robot">
              <mat-label>Created</mat-label>
              <input
                matInput
                formControlName="createdAt"
                type="datetime-local"
              />
            </mat-form-field>

            <!-- UpdatedAt -->
            <mat-form-field *ngIf="robot">
              <mat-label>Updated</mat-label>
              <input
                matInput
                formControlName="updatedAt"
                type="datetime-local"
              />
            </mat-form-field>

            <!-- Configuration -->
            <mat-form-field>
              <input
                matInput
                placeholder="Firmware"
                formControlName="firmware"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="RelativeZ"
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                formControlName="relativeZ"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="TOFHeight"
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                formControlName="tOFHeight"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Store1"
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                formControlName="store1"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Store2"
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                formControlName="store2"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="Store3"
                type="number"
                formControlName="store3"
              />
            </mat-form-field>
            <mat-form-field>
              <input
                matInput
                placeholder="StoreBrush"
                type="number"
                formControlName="storeBrush"
              />
            </mat-form-field>

            <!-- Enabled -->
            <div class="mat-slide-toggle">
              <mat-slide-toggle
                class="enabled-product-toogle sm"
                formControlName="enabled"
                color="primary"
                >Enabled</mat-slide-toggle
              >
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Submit Button -->
      <div>
        <button
          type="submit"
          style="width: 100%"
          [disabled]="!profileForm.valid || (isLoading$ | async)"
          mat-raised-button
          color="accent"
          class="md-button-right"
        >
          <div *ngIf="!robot">Create</div>
          <div *ngIf="robot">Update</div>
        </button>
      </div>
    </div>
  </form>
</div>
