import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { RobotDTO, RobotListDTO } from "../models/robots";
import { RobotsService } from "./robots.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class RobotsDataSource implements DataSource<RobotDTO>{

    private dataSubject = new BehaviorSubject<RobotDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private robotsService: RobotsService,
        private snackBar: MatSnackBar) { }

    loadData(pageSize: number,
        head: number, serial: string, enabled: boolean) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.robotsService.getList(pageSize, head, serial, enabled).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as RobotListDTO
                this.addDTOCorrections(listDTO);
                this.count = listDTO.totalRobots ? listDTO.totalRobots : 0;
                this.dataSubject.next(listDTO.robots ? listDTO.robots : []);
            },
            err => this.snackBar.open("Error retrieving robots from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<RobotDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    addDTOCorrections(listDTO: RobotListDTO) {
        if (listDTO && listDTO.robots) {
            listDTO.robots.forEach(robot => {
                if (robot.enabled) {
                    robot.enabledColor = "green";
                }
                else {
                    robot.enabledColor = "red";
                }
            });
        }
    }
}
