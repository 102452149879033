export class Util {

    public _filterModelByName(value: string, models?: any[]): any[] {
        let filterValue = value.toLowerCase();

        if (models)
            return models.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        else return []
    }

    public _filterModelById(id: number, models?: any[]): any {
        if (models) {
            let list: any[] = models.filter(option => option.id == id);
            if (list.length >= 1)
                return list[0]
            else return null
        }
        else return null
    }

    public displayName(model?: any): string {
        return model ? model.name : "";
    }
}