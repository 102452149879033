import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Util } from './util';

@Injectable({
  providedIn: 'root'
})
export class UtilDetailService extends Util {

  constructor(private snackBar: MatSnackBar) {
    super();
  }

  public showDetailSuccessMsg(isModelNew: boolean, modelName: string) {
    if (isModelNew)
      modelName += ' successfully created.';
    else {
      modelName += ' successfully updated.';
    }

    this.snackBar.open(modelName, 'Ok', {
      duration: 5000,
    });
  }

}

export function idSelectedValidator(Services?: any[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (Services) {
      const index = Services.findIndex(Service => {
        return new RegExp("^" + Service.name + "$").test(control.value);
      });
      return index < 0 ? { forbiddenNames: { value: control.value } } : null;
    } else return null;
  };
}