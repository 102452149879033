import { Injectable } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Location } from '@angular/common';
import { Util } from './util';
import { ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UtilListService extends Util {

  constructor(private location: Location) {
    super();
  }

  updateURLParams(component: string, paginator: MatPaginator, filterName: string, filter: string) {
    let queryParams: string = this.addPageSizeAndIndex(paginator);
    queryParams = this.addNameParam(queryParams, filterName, filter);
    this.location.replaceState('/' + component + queryParams);
  }

  updateURLParamsFilterAndEnabled(component: string, paginator: MatPaginator, filterName: string, filter: string, enabled: boolean) {
    let queryParams: string = this.addPageSizeAndIndex(paginator);
    queryParams = this.addNameParam(queryParams, filterName, filter);
    queryParams = this.addEnabledParam(queryParams, enabled);
    this.location.replaceState('/' + component + queryParams);
  }

  public addPageSizeAndIndex(paginator: MatPaginator) {
    let queryParams: string = '';
    queryParams += '?pageSize=' + paginator.pageSize;
    queryParams += '&pageIndex=' + paginator.pageIndex;
    return queryParams;
  }

  public addNameParam(queryParams: string, filterName: string, filter: string) {
    queryParams += '&' + filterName + '=' + (filter ? filter : '');
    return queryParams;
  }

  public addEnabledParam(queryParams: string, enabled: boolean) {
    queryParams += '&enabled=' + enabled;
    return queryParams;
  }

  getURLPageParams(paginator: MatPaginator, params: { [x: string]: any; }) {
    paginator.pageSize = params['pageSize'] ? params['pageSize'] : 10;
    paginator.pageIndex = params['pageIndex'] ? params['pageIndex'] : 0;
  }

  initPaginator(paginator: MatPaginator, activatedRoute: ActivatedRoute) {
    paginator.pageSize = activatedRoute.snapshot.params.pageSize ? activatedRoute.snapshot.params.pageSize : 10;
    paginator.pageIndex = activatedRoute.snapshot.params.pageIndex ? activatedRoute.snapshot.params.pageIndex : 0;
  }

  stringToBoolean(string: string) {
    switch (string.toLowerCase().trim()) {
      case "true": case "yes": case "1": return true;
      case "false": case "no": case "0": case null: return false;
      default: return Boolean(string);
    }
  }

}
