import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { VendorDTO, VendorListDTO } from "../models/vendors";
import { VendorsService } from "./vendors.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class VendorsDataSource implements DataSource<VendorDTO>{

    private dataSubject = new BehaviorSubject<VendorDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private vendorsService: VendorsService,
        private snackBar: MatSnackBar) { }

    loadData(pageSize: number,
        head: number, name: string, enabled: boolean) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.vendorsService.getList(pageSize, head, name, enabled).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as VendorListDTO
                this.count = listDTO.totalVendors ? listDTO.totalVendors : 0;
                this.dataSubject.next(listDTO.vendors ? listDTO.vendors : []);
            },
            err => this.snackBar.open("Error retrieving vendors from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<VendorDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
}
