<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Orders
        </button>
      </div>
      <div class="order-detail-header">
        <h1 *ngIf="order" class="mat-headline">Order {{ order.id }}</h1>
        <!-- Status -->
        <mat-chip-list *ngIf="order" class="order-detail-status">
          <mat-chip
            [style.background-color]="order.statusColor"
            [style.color]="'#f7f7f7'"
            class="mat-chip"
          >
            {{ order.status }}
          </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </section>
  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="cards-container">
      <!-- Product Info -->
      <mat-card class="card">
        <mat-card-content>
          <div class="card-content-container flex-direction-column">
            <div *ngIf="order" class="order-card">
              <span><strong>Producto</strong></span>
              <span><strong>Precio</strong></span>
              <span><strong>Cantidad</strong></span>
              <span><strong>Total</strong></span>
              <span><strong>Mercahnt</strong></span>
              <span>{{ order.product?.name }}</span>
              <span>
                <div *ngIf="order.total">${{ order.product?.price }}</div>
                <div *ngIf="!order.total">N/A</div>
              </span>
              <span>{{ order.quantity }}</span>
              <span
                ><div *ngIf="order.total">${{ order.total }}</div>
                <div *ngIf="!order.total">N/A</div></span
              >
              <span>{{ order.product?.vendor }}</span>
            </div>
            <div>
              <mat-form-field>
                <mat-label>Status</mat-label>
                <mat-select formControlName="status">
                  <mat-option
                    *ngFor="let status of statusList"
                    [value]="status"
                    >{{ status }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Submit Button -->
      <div>
        <button
          type="submit"
          style="width: 100%"
          [disabled]="!profileForm.valid || (isLoading$ | async)"
          mat-raised-button
          color="accent"
          class="md-button-right"
        >
          <div *ngIf="order">Update</div>
        </button>
      </div>
    </div>
  </form>
</div>
