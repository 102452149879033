import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { ProductDTO } from '../models/products';
import { ProductsService } from '../services/products.service';

@Injectable({
  providedIn: 'root'
})
export class ProductResolver implements Resolve<any> {
  constructor(private productsService: ProductsService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<ProductDTO> | undefined {
      let id: number = route.queryParams.Id as unknown as number
      if (id)
          return this.productsService.getById(id);
      else return undefined
  }
}
