import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { RobotListDTO, RobotDTO } from '../models/robots';

@Injectable({
  providedIn: 'root'
})
export class RobotsService {

  constructor(private http: HttpClient) { }

  getList(pageSize: number, head: number, serial: string, enabled: boolean): Observable<RobotListDTO> {

    let httpParams = new HttpParams()
    httpParams = serial ? httpParams.append('serial', serial) : httpParams;
    httpParams = httpParams.append('enabled', enabled.toString());

    const requestOptions = {
      params: httpParams
    };

    return this.http.get<RobotListDTO>(environment.serverAPI + 'robot/' + pageSize + '/' + head, requestOptions);
  }

  getListAutocomplet(): Observable<RobotDTO[]> {
    return this.http.get<RobotDTO[]>(environment.serverAPI + 'robot/');
  }

  getById(id: number): Observable<RobotDTO> {
    return this.http.get<RobotDTO>(environment.serverAPI + 'robot/' + id);
  }

  modify(robot: RobotDTO): Observable<RobotDTO> {
    if (robot.robotId)
      return this.update(robot);
    else
      return this.create(robot)
  }

  create(robot: RobotDTO): Observable<RobotDTO> {
    return this.http.post<RobotDTO>(environment.serverAPI + 'robot', robot);
  }

  update(robot: RobotDTO): Observable<RobotDTO> {
    return this.http.put<RobotDTO>(environment.serverAPI + 'robot', robot);
  }
}
