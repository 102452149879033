import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { VendorDTO } from 'src/app/models/vendors';
import { VendorsService } from 'src/app/services/vendors.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';

@Component({
  selector: 'app-vendor-details',
  templateUrl: './vendor-details.component.html',
  styleUrls: ['./vendor-details.component.scss']
})
export class VendorDetailsComponent implements OnInit {

  /* Form */
  vendor?: VendorDTO;
  profileForm!: FormGroup;

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;

  get f() { return this.profileForm.controls; }

  constructor(private fb: FormBuilder,
    private vendorsService: VendorsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    private utilDetailService: UtilDetailService) { }

  ngOnInit(): void {
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.vendor = this.route.snapshot.data.vendor as VendorDTO;
    this.initializeForm();
  }

  initializeForm() {
    this.profileForm = this.fb.group({
      id: [this.vendor ? this.vendor.id : null],
      name: [this.vendor ? this.vendor.name : null, Validators.required],
      email: [this.vendor ? this.vendor.email : null, [Validators.required, Validators.email,]],
      order: [this.vendor ? this.vendor.order : null, Validators.required],
      phone: [this.vendor ? this.vendor.phone : null, Validators.required],
      address: [this.vendor ? this.vendor.address : null],
      enabled: [this.vendor ? this.vendor.enabled : true, Validators.required],
      notes: [this.vendor ? this.vendor.notes : null],
    });
  }

  // Create or Update
  public onSubmit() {
    this.isLoadingService.add();
    let isModelNew = true;

    // Create DTO
    let vendorDTO: VendorDTO = {
      name: this.profileForm.value.name,
      email: this.profileForm.value.email,
      order: this.profileForm.value.order,
      phone: this.profileForm.value.phone,
      address: this.profileForm.value.address,
      enabled: this.profileForm.value.enabled,
      notes: this.profileForm.value.notes,
    }
    if (this.vendor) {
      vendorDTO.id = this.profileForm.value.id
      isModelNew = false
    }

    // Create or Update Vendor
    this.vendorsService.modify(vendorDTO).subscribe(async (result: VendorDTO) => {
      if (result != null && result.id) {
        this.vendor = result;
        this.location.replaceState('/vendor?Id=' + this.vendor.id);
        this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Vendor');
        this.location.back()
      } else {
        this.snackBar.open('Error creating or updating Vendor.', 'Ok');
        this.vendor = undefined;
      }
      this.isLoadingService.remove();
    });
  }

  backClicked() {
    this.location.back();
  }
}
