import { Injectable } from '@angular/core';
import {
    HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth/auth.service';
import { IsLoadingService } from '@service-work/is-loading';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    constructor(private snackBar: MatSnackBar, private authService: AuthService, private isLoadingService: IsLoadingService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpEvent<any>> {

        let authReq = this.addAuthorizationHeader(request);

        return next.handle(authReq)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    let errorMessage = '';
                    if (typeof error.error == "string") {
                        errorMessage = error.error;

                        if (error.status == 401) {
                            this.authService.logout()
                        }
                    }
                    else if (error.error instanceof ErrorEvent) {
                        // client-side error
                        errorMessage = `Error: ${error.error.message}`;
                    }
                    else {
                        // server-side error
                        if (error.status == 0) {
                            errorMessage = "Ups, an unknown error occurred."
                        }
                        if (error.status == 500) {
                            errorMessage = "Ups, an unknown internal server error occurred. "
                        }
                        else if (error.status == 401) {
                            errorMessage = "Authorization error."
                            this.authService.logout()
                        }
                        else {
                            errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
                        }
                    }

                    console.error(error); // log to console

                    this.snackBar.open(errorMessage, 'Ok'); // Show error to the user

                    this.isLoadingService.remove();

                    return throwError(errorMessage);
                })
            )
    }



    private addAuthorizationHeader(request: HttpRequest<any>) {
        let authReq = request;
        if (!request.url.includes("/login")) {
            let currentUserStr = localStorage.getItem('seedorinaCurrentUser');
            let currentUser: any;

            if (currentUserStr) {
                currentUser = JSON.parse(currentUserStr);
            }
            else
                this.snackBar.open("Current user JWT token not found ", "Ok");

            if (currentUser) {
                authReq = request.clone({
                    headers: request.headers.set('Authorization', currentUser.sessionToken)
                });
            }
        }
        return authReq;
    }
}