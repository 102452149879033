import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { VendorDTO } from '../models/vendors';
import { VendorsService } from '../services/vendors.service';

@Injectable({
  providedIn: 'root'
})
export class VendorResolver implements Resolve<any> {
  constructor(private vendorsService: VendorsService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<VendorDTO> | undefined {
      let id: number = route.queryParams.Id as unknown as number
      if (id)
          return this.vendorsService.getById(id);
      else return undefined
  }
}
