<!-- Loading spinner -->
<mat-spinner
  *ngIf="loading"
  [diameter]="50"
  style="margin: 0 auto; margin-top: 20%"
>
</mat-spinner>

<mat-card *ngIf="!loading">
  <mat-card-title class="login-title">Seedorina Backoffice</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <p>
        <mat-form-field>
          <input
            type="text"
            matInput
            placeholder="Username"
            formControlName="username"
            autocomplete="username"
          />
        </mat-form-field>
      </p>

      <p>
        <mat-form-field>
          <input
            type="password"
            matInput
            placeholder="Password"
            formControlName="password"
            autocomplete="current-password"
          />
        </mat-form-field>
      </p>

      <div class="button">
        <button mat-button mat-stroked-button color="accent" type="submit">
          Login
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
