import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderDTO, OrderStatus } from 'src/app/models/orders';
import { OrdersService } from 'src/app/services/orders.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';
import { OrdersResolver } from 'src/app/resolvers/orders.resolver';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  /* Form */
  order?: OrderDTO;
  profileForm!: FormGroup;

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;

  statusList?: string[]

  get f() { return this.profileForm.controls; }

  constructor(private fb: FormBuilder,
    private ordersService: OrdersService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    private utilDetailService: UtilDetailService) { }

  ngOnInit(): void {
    this.order = this.route.snapshot.data.order as OrderDTO;
    this.ordersService.setOrderStatus(this.order);
    this.initializeForm();
    this.isLoading$ = this.isLoadingService.isLoading$();

    this.statusList = [OrderStatus.pending, OrderStatus.pendingPayment, OrderStatus.approved, OrderStatus.delivered, OrderStatus.refunded, OrderStatus.cancel];
  }

  initializeForm() {
    this.profileForm = this.fb.group({
      id: [this.order ? this.order.id : null],
      status: [this.order ? this.order.status : null, Validators.required],
    });
  }

  // Create or Update
  public onSubmit() {
    this.isLoadingService.add();
    let isModelNew = true;

    // Create DTO
    let orderDTO: OrderDTO = {
      status: this.profileForm.value.status
    }
    if (this.order) {
      orderDTO.id = this.profileForm.value.id
      isModelNew = false
    }

    // Create or Update Order
    this.ordersService.modify(orderDTO).subscribe(async (result: OrderDTO) => {
      if (result != null && result.id) {
        this.order = result;
        this.location.replaceState('/order?Id=' + this.order.id);
        this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Order');
        this.location.back()
      } else {
        this.snackBar.open('Error creating or updating Order.', 'Ok');
        this.order = undefined;
      }
      this.isLoadingService.remove();
    });
  }

  backClicked() {
    this.location.back();
  }
}
