<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Seeds
        </button>
      </div>
      <div>
        <h1 *ngIf="!seed" class="mat-headline">Create Seed</h1>
        <h1 *ngIf="seed" class="mat-headline">Update Seed</h1>
      </div>
    </div>
  </section>

  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="cards-container">
      <!-- Product Info -->
      <mat-card class="card">
        <mat-card-content>
          <div class="card-content-container">
            <!-- Name -->
            <mat-form-field>
              <input matInput placeholder="Name" formControlName="name" />
              <mat-error *ngIf="f.name.errors && f.name.errors.required">
                Name is <strong>mandatory</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Submit Button -->
      <div>
        <button
          type="submit"
          style="width: 100%"
          [disabled]="!profileForm.valid || (isLoading$ | async)"
          mat-raised-button
          color="accent"
          class="md-button-right"
        >
          <div *ngIf="!seed">Create</div>
          <div *ngIf="seed">Update</div>
        </button>
      </div>
    </div>
  </form>
</div>
