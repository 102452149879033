import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { ProgramsDataSource } from 'src/app/services/programs.datasource';
import { ProgramsService } from 'src/app/services/programs.service';
import { UtilListService } from 'src/app/services/shared/util-list.service';

@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss']
})
export class ProgramListComponent implements OnInit {

  /* Loading */
  loading: boolean = true;

  /* Table Attributes */
  dataSource!: ProgramsDataSource;
  displayedColumns: string[] = ['name', 'version', 'updatedAt', 'enabled'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  /* Filters Form */
  profileForm: FormGroup;

  constructor(private programsService: ProgramsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private utilListService: UtilListService) {
    this.profileForm = fb.group({
      name: [null],
      enabled: [true],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.loadURLParams();
      this.initTable();
    });
  }

  private initTable() {
    this.dataSource = new ProgramsDataSource(this.programsService, this.snackBar);
    this.loadTable();
    this.dataSource.loading$.subscribe(result => { this.loading = result; });
  }

  private loadURLParams() {
    this.utilListService.initPaginator(this.paginator, this.route);
    this.profileForm.controls.name.setValue(this.route.snapshot.params.name ? this.route.snapshot.params.name : '');
    this.profileForm.controls.enabled.setValue(this.route.snapshot.params.enabled != undefined ? this.utilListService.stringToBoolean(this.route.snapshot.params.enabled) : true);
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadTable())
      )
      .subscribe();
  }

  applyFilter(): void {
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  loadTable() {
    this.dataSource?.loadData(
      this.paginator.pageSize,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.profileForm.value.name, this.profileForm.value.enabled);

    this.updateURLParams();
  }

  updateURLParams() {
    this.router.navigate(
      [
        {
          pageSize: (this.paginator.pageSize ? this.paginator.pageSize : ''),
          pageIndex: (this.paginator.pageIndex ? this.paginator.pageIndex : ''),
          name: (this.profileForm.value.name ? this.profileForm.value.name : ''),
          enabled: (this.profileForm.value.enabled ? this.profileForm.value.enabled : ''),
        }
      ],
      {
        relativeTo: this.route,
        replaceUrl: true
      }
    );
  }
  
  rowClicked(row: { programId: string; }) {
    this.router.navigate(['/program'], { queryParams: { Id: row.programId } });
  }

}
