import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { VendorDTO } from 'src/app/models/vendors';
import { ProductsDataSource } from 'src/app/services/products.datasource';
import { ProductsService } from 'src/app/services/products.service';
import { UtilListService } from 'src/app/services/shared/util-list.service';
import { Location } from '@angular/common';
import { CategoryDTO, SubcategoryDTO } from 'src/app/models/products';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit {

  /* Loading */
  loading: boolean = true;

  /* Table Attributes */
  dataSource!: ProductsDataSource;
  displayedColumns: string[] = ['name', 'category', 'subCategory', 'enabled'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  /* Filters Form */
  profileForm: FormGroup;
  filteredVendors: Observable<VendorDTO[] | undefined> | undefined;
  filteredCategories: Observable<CategoryDTO[] | undefined> | undefined;
  filteredSubcategory: Observable<SubcategoryDTO[] | undefined> | undefined;
  vendors?: VendorDTO[];
  categories?: CategoryDTO[];
  subcategories?: SubcategoryDTO[];
  subcategoriesOfSelectedCategory?: SubcategoryDTO[];

  constructor(private productsService: ProductsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public utilListService: UtilListService,
    private location: Location,
  ) {
    this.profileForm = fb.group({
      name: [null],
      enabled: [true],
      vendor: [null],
      category: [null],
      subCategory: [null],
    });
  }

  ngOnInit(): void {
    // Initi autocomplet filters
    this.autocompletesValueChangeHandler();

    this.route.queryParams.subscribe(params => {
      this.loadURLParams();
      this.initTable();
    });
  }

  private initTable() {
    this.dataSource = new ProductsDataSource(this.productsService, this.snackBar);
    this.loadTable();
    this.dataSource.loading$.subscribe(result => { this.loading = result; });
  }

  // Get Params and initialize profile form
  private loadURLParams() {
    this.utilListService.initPaginator(this.paginator, this.route);
    this.profileForm.controls.name.setValue(this.route.snapshot.params.name ? this.route.snapshot.params.name : '');
    this.profileForm.controls.enabled.setValue(this.route.snapshot.params.enabled != undefined ? this.utilListService.stringToBoolean(this.route.snapshot.params.enabled) : true);
    this.profileForm.controls.vendor.setValue(this.utilListService._filterModelById(this.route.snapshot.params.vendorId, this.vendors));
    this.profileForm.controls.category.setValue(this.utilListService._filterModelById(this.route.snapshot.params.categoryId, this.categories));
    this.selectedCategoryEvent(this.profileForm.controls.category.value);
    this.profileForm.controls.subCategory.setValue(this.utilListService._filterModelById(this.route.snapshot.params.subCategoryId, this.subcategories));
  }

  autocompletesValueChangeHandler() {
    this.vendors = this.route.snapshot.data.vendors as VendorDTO[];
    this.categories = this.route.snapshot.data.categories as CategoryDTO[];
    this.subcategories = this.route.snapshot.data.subcategories as SubcategoryDTO[];
    this.subcategoriesOfSelectedCategory = this.subcategories;

    this.filteredVendors = this.profileForm.controls.vendor.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilListService._filterModelByName(name, this.vendors) : this.vendors?.slice())
      );

    this.filteredCategories = this.profileForm.controls.category.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilListService._filterModelByName(name, this.categories) : this.categories?.slice())
      );
  }

  private subCategoryFilterValueChangeHandler() {
    this.filteredSubcategory = this.profileForm.controls.subCategory.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilListService._filterModelByName(name, this.subcategoriesOfSelectedCategory) : this.subcategoriesOfSelectedCategory?.slice())
      );
  }

  selectedCategoryEvent(selectedCategory: CategoryDTO) {
    if (this.subcategories && this.subcategories.length > 0 && selectedCategory) {
      this.subcategoriesOfSelectedCategory = this.subcategories.filter(option => option.categoryId == selectedCategory.id);
      this.profileForm.controls.subCategory.setValue("")
      this.subCategoryFilterValueChangeHandler();
    }

    // Enable or disable SubCategory select
    if (this.subcategoriesOfSelectedCategory && this.subcategoriesOfSelectedCategory.length > 0 && selectedCategory) {
      this.profileForm.controls.subCategory.enable()
    }
    else this.profileForm.controls.subCategory.disable()
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadTable())
      )
      .subscribe();
  }

  applyFilter(): void {
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  loadTable() {
    this.dataSource?.loadData(
      this.paginator.pageSize,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.profileForm.value.name, this.profileForm.value.enabled,
      this.profileForm.value.vendor ? this.profileForm.value.vendor.id : null,
      this.profileForm.value.category ? this.profileForm.value.category.id : null,
      this.profileForm.value.subCategory ? this.profileForm.value.subCategory.id : null);

    this.updateURLParams();
  }

  updateURLParams() {
    this.router.navigate(
      [
        {
          pageSize: (this.paginator.pageSize ? this.paginator.pageSize : ''),
          pageIndex: (this.paginator.pageIndex ? this.paginator.pageIndex : ''),
          name: (this.profileForm.value.name ? this.profileForm.value.name : ''),
          enabled: (this.profileForm.value.enabled ? this.profileForm.value.enabled : ''),
          vendorId: (this.profileForm.value.vendor ? this.profileForm.value.vendor.id : ''),
          categoryId: (this.profileForm.value.category ? this.profileForm.value.category.id : ''),
          subCategoryId: (this.profileForm.value.subCategory ? this.profileForm.value.subCategory.id : ''),
        }
      ],
      {
        relativeTo: this.route,
        replaceUrl: true
      }
    );
  }

  rowClicked(row: { id: string; }) {
    this.router.navigate(['/product'], { queryParams: { Id: row.id } });
  }

}
