<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Merchants
        </button>
      </div>
      <div>
        <h1 *ngIf="!vendor" class="mat-headline">Create Merchant</h1>
        <h1 *ngIf="vendor" class="mat-headline">Update Merchant</h1>
      </div>
    </div>
  </section>

  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <div class="cards-container">
      <!-- Product Info -->
      <mat-card class="card">
        <mat-card-content>
          <div class="card-content-container">
            <!-- Name -->
            <mat-form-field>
              <input matInput placeholder="Name" formControlName="name" />
              <mat-error *ngIf="f.name.errors && f.name.errors.required">
                Name is <strong>mandatory</strong>
              </mat-error>
            </mat-form-field>

            <!-- Email -->
            <mat-form-field>
              <mat-label>Email</mat-label>
              <input
                type="email"
                matInput
                formControlName="email"
                placeholder="Ex. pat@example.com"
              />
              <mat-error *ngIf="f.email.errors && !f.email.errors.required">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="f.email.errors && f.email.errors.required">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <!-- Order -->
            <mat-form-field>
              <input
                matInput
                placeholder="Order"
                type="number"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                formControlName="order"
              />
            </mat-form-field>

            <!-- Phone -->
            <mat-form-field>
              <mat-label>Telephone</mat-label>
              <span matPrefix>+598 &nbsp;</span>
              <input
                type="tel"
                matInput
                placeholder="55 555 555"
                formControlName="phone"
              />
            </mat-form-field>

            <!-- Address -->
            <mat-form-field>
              <mat-label>Address</mat-label>
              <input
                matInput
                placeholder="Ex. Rivera 5848 Apt 001"
                formControlName="address"
              />
            </mat-form-field>

            <!-- Enabled -->
            <div class="mat-slide-toggle">
              <mat-slide-toggle
                class="enabled-product-toogle sm"
                formControlName="enabled"
                color="primary"
                >Enabled</mat-slide-toggle
              >
            </div>

            <!-- Notes -->
            <mat-form-field class="full-width">
              <mat-label>Notes</mat-label>
              <textarea
                matInput
                placeholder="Add vendors notes"
                formControlName="notes"
              ></textarea>
            </mat-form-field>
          </div>
        </mat-card-content>
      </mat-card>

      <!-- Submit Button -->
      <div>
        <button
          type="submit"
          style="width: 100%"
          [disabled]="!profileForm.valid || (isLoading$ | async)"
          mat-raised-button
          color="accent"
          class="md-button-right"
        >
          <div *ngIf="!vendor">Create</div>
          <div *ngIf="vendor">Update</div>
        </button>
      </div>
    </div>
  </form>
</div>
