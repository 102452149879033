import { Injectable } from '@angular/core';
import { OrderDTO } from '../models/orders';
import { OrdersService } from '../services/orders.service';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersResolver implements Resolve<any> {
  constructor(private ordersService: OrdersService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrderDTO> | undefined {
    let id: number = route.queryParams.Id as unknown as number
    if (id)
      return this.ordersService.getById(id);
    else return undefined
  }
}
