import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { SeedsDataSource } from 'src/app/services/seeds.datasource';
import { SeedsService } from 'src/app/services/seeds.service';
import { UtilListService } from 'src/app/services/shared/util-list.service';

@Component({
  selector: 'app-seed-list',
  templateUrl: './seed-list.component.html',
  styleUrls: ['./seed-list.component.scss']
})
export class SeedListComponent implements OnInit {

  /* Loading */
  loading: boolean = true;

  /* Table Attributes */
  dataSource!: SeedsDataSource;
  displayedColumns: string[] = ['name'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  /* Filters Form */
  profileForm: FormGroup;

  constructor(private seedsService: SeedsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private utilListService: UtilListService) {
    this.profileForm = fb.group({
      name: [null],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.loadURLParams();
      this.initTable();
    });
  }

  private initTable() {
    this.dataSource = new SeedsDataSource(this.seedsService, this.snackBar);
    this.loadTable();
    this.dataSource.loading$.subscribe(result => { this.loading = result; });
  }

  private loadURLParams() {
    this.utilListService.initPaginator(this.paginator, this.route);
    this.profileForm.controls.name.setValue(this.route.snapshot.params.name ? this.route.snapshot.params.name : '');
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadTable())
      )
      .subscribe();
  }

  applyFilter(): void {
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  loadTable() {
    this.dataSource?.loadData(
      this.paginator.pageSize,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.profileForm.value.name);

    this.updateURLParams();
  }

  updateURLParams() {
    this.router.navigate(
      [
        {
          pageSize: (this.paginator.pageSize ? this.paginator.pageSize : ''),
          pageIndex: (this.paginator.pageIndex ? this.paginator.pageIndex : ''),
          name: (this.profileForm.value.name ? this.profileForm.value.name : ''),
        }
      ],
      {
        relativeTo: this.route,
        replaceUrl: true
      }
    );
  }

  rowClicked(row: { id: string; }) {
    this.router.navigate(['/seed'], { queryParams: { Id: row.id } });
  }

}
