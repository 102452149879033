<div class="container">
  <div class="title-list">
    <div><h1 class="mat-display-1 h1-list">Products</h1></div>
    <div
      class="button-add"
      [routerLinkActive]="['active']"
      [routerLink]="['/product']"
    >
      <button mat-flat-button color="accent">New Product</button>
    </div>
  </div>
  <div class="mat-elevation-z2">
    <!-- Filters -->
    <Form
      #filters
      [formGroup]="profileForm"
      class="table-header-filter-container"
    >
      <!-- Name -->
      <mat-form-field class="table-header-filter">
        <input matInput formControlName="name" placeholder="Name"
      /></mat-form-field>

      <!-- Vendor -->
      <mat-form-field class="table-header-filter">
        <input
          type="text"
          placeholder="Pick a Merchant"
          aria-label="Merchant"
          matInput
          formControlName="vendor"
          [matAutocomplete]="autoVendor"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoVendor="matAutocomplete"
          [displayWith]="utilListService.displayName"
        >
          <mat-option
            *ngFor="let vendor of filteredVendors | async"
            [value]="vendor"
          >
            {{ vendor.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Category -->
      <mat-form-field class="table-header-filter">
        <input
          type="text"
          placeholder="Pick a Category"
          aria-label="Category"
          matInput
          formControlName="category"
          [matAutocomplete]="autoCategory"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoCategory="matAutocomplete"
          [displayWith]="utilListService.displayName"
          (optionSelected)="selectedCategoryEvent($event.option.value)"
        >
          <mat-option
            *ngFor="let category of filteredCategories | async"
            [value]="category"
          >
            {{ category.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Sub Category -->
      <mat-form-field class="table-header-filter">
        <input
          type="text"
          placeholder="Pick a Sub-category"
          aria-label="SubCategory"
          matInput
          formControlName="subCategory"
          [matAutocomplete]="autoSubcategory"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoSubcategory="matAutocomplete"
          [displayWith]="utilListService.displayName"
        >
          <mat-option
            *ngFor="let subcategory of filteredSubcategory | async"
            [value]="subcategory"
          >
            {{ subcategory.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Enabled -->
      <mat-slide-toggle
        class="table-header-filter"
        formControlName="enabled"
        color="primary"
        >Enabled</mat-slide-toggle
      >
      <!-- Search button-->
      <div class="table-header-icon">
        <button
          mat-icon-button
          type="submit"
          [disabled]="!profileForm.valid"
          (click)="applyFilter()"
          color="primary"
          aria-label="Filter"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </Form>

    <!-- Table -->
    <div #table class="table-container">
      <table mat-table [dataSource]="dataSource">
        <!-- Name -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <!-- Category -->
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element">
            {{ element.category }}
          </td>
        </ng-container>

        <!-- SubCategory -->
        <ng-container matColumnDef="subCategory">
          <th mat-header-cell *matHeaderCellDef>SubCategory</th>
          <td mat-cell *matCellDef="let element">
            {{ element.subCategory }}
          </td>
        </ng-container>

        <!-- Enabled -->
        <ng-container matColumnDef="enabled">
          <th mat-header-cell *matHeaderCellDef>Enabled</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.color]="element.enabledColor"
          >
            {{ element.enabled }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="element-row"
          (click)="rowClicked(row)"
        ></tr>
      </table>
      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <mat-paginator
        #paginator
        [length]="dataSource?.count"
        [pageSize]="this.paginator.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
  </div>
</div>
