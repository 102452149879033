import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LogInComponent } from './components/log-in/log-in.component';
import { RobotDetailsComponent } from './components/robots/robot-details/robot-details.component';
import { RobotListComponent } from './components/robots/robot-list/robot-list.component';
import { RobotResolver } from './resolvers/robot.resolver';
import { RobotsResolver } from './resolvers/robots.resolver';
import { CompanyDetailsComponent } from './components/companies/company-details/company-details.component';
import { CompanyListComponent } from './components/companies/company-list/company-list.component';
import { CompanyResolver } from './resolvers/company.resolver';
import { SeedListComponent } from './components/seeds/seed-list/seed-list.component';
import { SeedDetailsComponent } from './components/seeds/seed-details/seed-details.component';
import { SeedResolver } from './resolvers/seed.resolver';
import { ProgramListComponent } from './components/programs/program-list/program-list.component';
import { ProgramDetailsComponent } from './components/programs/program-details/program-details.component';
import { ProgramResolver } from './resolvers/program.resolver';
import { VendorListComponent } from './components/vendors/vendor-list/vendor-list.component';
import { VendorDetailsComponent } from './components/vendors/vendor-details/vendor-details.component';
import { VendorResolver } from './resolvers/vendor.resolver';
import { VendorsResolver } from './resolvers/vendors.resolver';
import { ProductListComponent } from './components/products/product-list/product-list.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { ProductResolver } from './resolvers/product.resolver';
import { CategoriesResolver } from './resolvers/categories.resolver';
import { SubcategoriesResolver } from './resolvers/subcategories.resolver';
import { OrderListComponent } from './components/orders/order-list/order-list.component';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';
import { OrdersResolver } from './resolvers/orders.resolver';

const routes: Routes = [
  {
    path: '',
    component: RobotListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LogInComponent,
  },
  {
    path: 'robots',
    component: RobotListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'robot',
    component: RobotDetailsComponent,
    canActivate: [AuthGuard],
    resolve: { robot: RobotResolver }
  },
  {
    path: 'companies',
    component: CompanyListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'company',
    component: CompanyDetailsComponent,
    canActivate: [AuthGuard],
    resolve: {
      company: CompanyResolver,
      robots: RobotsResolver
    }
  },
  {
    path: 'seeds',
    component: SeedListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'seed',
    component: SeedDetailsComponent,
    canActivate: [AuthGuard],
    resolve: { seed: SeedResolver }
  },
  {
    path: 'program',
    component: ProgramDetailsComponent,
    canActivate: [AuthGuard],
    resolve: { program: ProgramResolver }
  },
  {
    path: 'programs',
    component: ProgramListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vendor',
    component: VendorDetailsComponent,
    canActivate: [AuthGuard],
    resolve: { vendor: VendorResolver }
  },
  {
    path: 'vendors',
    component: VendorListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'product',
    component: ProductDetailsComponent,
    canActivate: [AuthGuard],
    resolve: {
      product: ProductResolver,
      vendors: VendorsResolver,
      categories:CategoriesResolver,
      subcategories:SubcategoriesResolver,
    }
  },
  {
    path: 'products',
    component: ProductListComponent,
    canActivate: [AuthGuard],
    resolve: {
      vendors: VendorsResolver,
      categories:CategoriesResolver,
      subcategories:SubcategoriesResolver,
    }
  },
  {
    path: 'order',
    component: OrderDetailsComponent,
    canActivate: [AuthGuard],
    resolve: { order: OrdersResolver }
  },
  {
    path: 'orders',
    component: OrderListComponent,
    canActivate: [AuthGuard],
    resolve: {
      vendors: VendorsResolver
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
