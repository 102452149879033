import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { VendorDTO, VendorListDTO } from '../models/vendors';


@Injectable({
  providedIn: 'root'
})
export class VendorsService {

  constructor(private http: HttpClient) { }

  getList(pageSize: number, head: number, name: string, enabled: boolean): Observable<VendorListDTO> {

    let httpParams = new HttpParams()
    httpParams = name ? httpParams.append('name', name) : httpParams;
    httpParams = httpParams.append('enabled', enabled.toString());

    const requestOptions = {
      params: httpParams
    };

    return this.http.get<VendorListDTO>(environment.serverAPI + 'vendor/' + pageSize + '/' + head, requestOptions);
  }

  getListAutocomplet(): Observable<VendorDTO[]> {
    return this.http.get<VendorDTO[]>(environment.serverAPI + 'vendor/');
  }

  getById(id: number): Observable<VendorDTO> {
    return this.http.get<VendorDTO>(environment.serverAPI + 'vendor/' + id);
  }

  modify(vendor: VendorDTO): Observable<VendorDTO> {
    if (vendor.id)
      return this.update(vendor);
    else
      return this.create(vendor)
  }

  create(vendor: VendorDTO): Observable<VendorDTO> {
    return this.http.post<VendorDTO>(environment.serverAPI + 'vendor', vendor);
  }

  update(vendor: VendorDTO): Observable<VendorDTO> {
    return this.http.put<VendorDTO>(environment.serverAPI + 'vendor', vendor);
  }

}
