import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IsLoadingModule } from '@service-work/is-loading';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';

/* Material */
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatInputModule } from "@angular/material/input";
import { MatTableModule } from "@angular/material/table";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule } from '@angular/material/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';

/* App */
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { LogInComponent } from './components/log-in/log-in.component';
import { RobotDetailsComponent } from './components/robots/robot-details/robot-details.component';
import { RobotListComponent } from './components/robots/robot-list/robot-list.component';
import { RobotResolver } from './resolvers/robot.resolver';
import { CompanyDetailsComponent } from './components/companies/company-details/company-details.component';
import { CompanyListComponent } from './components/companies/company-list/company-list.component';
import { CompanyResolver } from './resolvers/company.resolver';
import { SeedDetailsComponent } from './components/seeds/seed-details/seed-details.component';
import { SeedListComponent } from './components/seeds/seed-list/seed-list.component';
import { SeedResolver } from './resolvers/seed.resolver';
import { ProgramDetailsComponent } from './components/programs/program-details/program-details.component';
import { ProgramListComponent } from './components/programs/program-list/program-list.component';
import { ProgramResolver } from './resolvers/program.resolver';
import { RobotSelectComponent } from './components/robots/robot-select/robot-select.component';
import { RobotsResolver } from './resolvers/robots.resolver';
import { VendorDetailsComponent } from './components/vendors/vendor-details/vendor-details.component';
import { VendorListComponent } from './components/vendors/vendor-list/vendor-list.component';
import { ProductDetailsComponent } from './components/products/product-details/product-details.component';
import { ProductListComponent } from './components/products/product-list/product-list.component';
import { OrderDetailsComponent } from './components/orders/order-details/order-details.component';
import { OrderListComponent } from './components/orders/order-list/order-list.component';

export const httpInterceptorProviders = [{
  provide: HTTP_INTERCEPTORS,
  useClass: HttpErrorInterceptor,
  multi: true
}];

@NgModule({
  declarations: [
    AppComponent,
    RobotListComponent,
    RobotDetailsComponent,
    CompanyDetailsComponent,
    CompanyListComponent,
    LogInComponent,
    SeedDetailsComponent,
    SeedListComponent,
    ProgramDetailsComponent,
    ProgramListComponent,
    RobotSelectComponent,
    VendorDetailsComponent,
    VendorListComponent,
    ProductDetailsComponent,
    ProductListComponent,
    OrderDetailsComponent,
    OrderListComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IsLoadingModule,
    HttpClientModule,
    ReactiveFormsModule,
    TextFieldModule,

    /* Material */
    MatSidenavModule,
    MatToolbarModule,
    MatIconModule,
    MatListModule,
    MatButtonModule,
    MatProgressBarModule,
    MatPaginatorModule,
    MatInputModule,
    MatTableModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatSelectModule
  ],
  providers: [
    AuthGuard,
    httpInterceptorProviders,
    RobotResolver,
    RobotsResolver,
    CompanyResolver,
    SeedResolver,
    ProgramResolver,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
