import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { HttpClient } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  loggedIn$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient,
    private snackBar: MatSnackBar,
    private router: Router) {

    if (localStorage.getItem('seedorinaCurrentUser')) {
      this.loggedIn$.next(true);
    }
    else this.loggedIn$.next(false);
  }

  public isAuthenticated() {
    if (localStorage.getItem('seedorinaCurrentUser'))
      return true;
    else return false;
  }

  login(username: string, password: string): Observable<any> {
    return this.http.post<any>(environment.serverAPI + 'admin/login', { email: username, password: password }).pipe(
      tap(
        data => {
          // login successful if there's a token in the response
          if (data['email'] && data['sessionToken']) {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('seedorinaCurrentUser', JSON.stringify(data));
          };

          this.loggedIn$.next(true);
        },
        error => {
          this.snackBar.open('Error login in.', 'Ok');
        }
      ),
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('seedorinaCurrentUser');
    this.loggedIn$.next(false);
  }
}
