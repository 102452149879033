import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { SeedDTO } from 'src/app/models/seeds';
import { SeedsService } from 'src/app/services/seeds.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';

@Component({
  selector: 'app-seed-details',
  templateUrl: './seed-details.component.html',
  styleUrls: ['./seed-details.component.scss']
})
export class SeedDetailsComponent implements OnInit {

  /* Form */
  seed?: SeedDTO;
  profileForm!: FormGroup;

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;

  get f() { return this.profileForm.controls; }

  constructor(private fb: FormBuilder,
    private seedsService: SeedsService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    private utilDetailService: UtilDetailService) { }

  ngOnInit(): void {
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.seed = this.route.snapshot.data.seed as SeedDTO;
    this.initializeForm();
  }

  initializeForm() {
    this.profileForm = this.fb.group({
      id: [this.seed ? this.seed.id : null],
      name: [this.seed ? this.seed.name : null, Validators.required],
    });
  }

  // Create or Update
  public onSubmit() {
    this.isLoadingService.add();
    let isModelNew = true;

    // Create DTO
    let seedDTO: SeedDTO = {
      name: this.profileForm.value.name,
    }
    if (this.seed) {
      seedDTO.id = this.profileForm.value.id
      isModelNew = false
    }

    // Create or Update Seed
    this.seedsService.modify(seedDTO).subscribe(async (result: SeedDTO) => {
      if (result != null && result.id) {
        this.seed = result;
        this.location.replaceState('/seed?Id=' + this.seed.id);
        this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Seed');
        this.location.back()
      } else {
        this.snackBar.open('Error creating or updating Seed.', 'Ok');
        this.seed = undefined;
      }
      this.isLoadingService.remove();
    });
  }

  backClicked() {
    this.location.back();
  }
}
