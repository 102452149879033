<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Programs
        </button>
      </div>
      <div>
        <h1 *ngIf="!program" class="mat-headline">New Program</h1>
        <h1 *ngIf="program" class="mat-headline">Update Program</h1>
      </div>
    </div>
  </section>

  <form *ngIf="profileForm" [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <!-- Product Info -->
    <mat-card class="card">
      <mat-card-content>
        <div class="card-content-container">
          <!-- Name -->
          <mat-form-field>
            <input matInput placeholder="Name" formControlName="name" />
            <mat-error *ngIf="f.name.errors && f.name.errors.required">
              Name is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>
          <!-- Version -->
          <mat-form-field *ngIf="program">
            <input matInput placeholder="Version" formControlName="version" />
          </mat-form-field>
          <!-- Seeds -->
          <mat-form-field>
            <input
              type="text"
              placeholder="Pick a seed"
              aria-label="Seed"
              matInput
              formControlName="seed"
              [matAutocomplete]="auto"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #auto="matAutocomplete"
              [displayWith]="displaySeedName"
            >
              <mat-option
                *ngFor="let seed of filteredSeeds | async"
                [value]="seed"
              >
                {{ seed.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.seed.errors && f.seed.errors.required">
              Seed is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>
          <!-- CreatedAt -->
          <mat-form-field *ngIf="program">
            <mat-label>Created</mat-label>
            <input matInput formControlName="createdAt" type="datetime-local" />
          </mat-form-field>

          <!-- UpdatedAt -->
          <mat-form-field *ngIf="program">
            <mat-label>Updated</mat-label>
            <input matInput formControlName="updatedAt" type="datetime-local" />
          </mat-form-field>

          <!-- Enabled -->
          <div class="mat-slide-toggle">
            <mat-slide-toggle
              class="enabled-product-toogle sm"
              formControlName="enabled"
              color="primary"
              >Enabled</mat-slide-toggle
            >
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <!-- Code -->
    <div class="code-container">
      <mat-form-field class="code" [class.mat-elevation-z2]="true">
        <!-- <input matInput placeholder="Code" formControlName="code" /> -->
        <textarea
          matInput
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="10"
          cdkAutosizeMaxRows="50"
          placeholder="source code"
          formControlName="code"
        ></textarea>
        <mat-error *ngIf="f.code.errors && f.code.errors.required">
          Code is <strong>mandatory</strong>
        </mat-error>
      </mat-form-field>
    </div>
    <!-- Submit Button -->
    <div>
      <button
        type="submit"
        style="width: 100%"
        [disabled]="!profileForm.valid || (isLoading$ | async)"
        mat-raised-button
        color="accent"
        class="md-button-right"
      >
        <div *ngIf="!program">Create</div>
        <div *ngIf="program">Update</div>
      </button>
    </div>
  </form>
</div>
