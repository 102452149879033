import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { ProgramDTO, ProgramListDTO } from "../models/programs";
import { ProgramsService } from "./programs.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class ProgramsDataSource implements DataSource<ProgramDTO>{

    private dataSubject = new BehaviorSubject<ProgramDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private programsService: ProgramsService,
        private snackBar: MatSnackBar) { }

    loadData(pageSize: number,
        head: number, serial: string, enabled: boolean) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.programsService.getList(pageSize, head, serial, enabled).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as ProgramListDTO
                this.addDTOCorrections(listDTO);
                this.count = listDTO.totalPrograms ? listDTO.totalPrograms : 0;
                this.dataSubject.next(listDTO.programs ? listDTO.programs : []);
            },
            err => this.snackBar.open("Error retrieving programs from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<ProgramDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }

    addDTOCorrections(listDTO: ProgramListDTO) {
        if (listDTO && listDTO.programs) {
            listDTO.programs.forEach(program => {
                if (program.enabled) {
                    program.enabledColor = "green";
                }
                else {
                    program.enabledColor = "red";
                }
            });
        }
    }
}
