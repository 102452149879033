<div class="container">
  <div class="title-list">
    <div><h1 class="mat-display-1 h1-list">Robots</h1></div>
    <div
      class="button-add"
      [routerLinkActive]="['active']"
      [routerLink]="['/robot']"
    >
      <button mat-flat-button color="accent">New Robot</button>
    </div>
  </div>
  <div class="mat-elevation-z2">
    <!-- Filters -->
    <Form
      #filters
      [formGroup]="profileForm"
      class="table-header-filter-container"
    >
      <!-- Serial -->
      <mat-form-field class="table-header-filter">
        <input matInput formControlName="serial" placeholder="Serial"
      /></mat-form-field>
      <!-- Enabled -->
      <mat-slide-toggle
        class="table-header-filter"
        formControlName="enabled"
        color="primary"
        >Enabled</mat-slide-toggle
      >
      <!-- Search button-->
      <div class="table-header-icon">
        <button
          mat-icon-button
          type="submit"
          [disabled]="!profileForm.valid"
          (click)="applyFilter()"
          color="primary"
          aria-label="Filter"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </Form>

    <!-- Table -->
    <div #table class="table-container">
      <table
        mat-table
        [dataSource]="dataSource"
      >
        <!-- Serial -->
        <ng-container matColumnDef="serial">
          <th mat-header-cell *matHeaderCellDef>Serial</th>
          <td mat-cell *matCellDef="let element">
            {{ element.serial }}
          </td>
        </ng-container>

        <!-- CreatedAt  -->
        <ng-container matColumnDef="createdAt" class="xxl">
          <th mat-header-cell *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date: "h:mm a, d/M":"UTC -6" }}
          </td>
        </ng-container>

        <!-- UpdatedAt  -->
        <ng-container matColumnDef="updatedAt" class="xxl">
          <th mat-header-cell *matHeaderCellDef>Updated</th>
          <td mat-cell *matCellDef="let element">
            {{ element.updatedAt | date: "h:mm a, d/M":"UTC -6" }}
          </td>
        </ng-container>

        <!-- Enabled -->
        <ng-container matColumnDef="enabled">
          <th mat-header-cell *matHeaderCellDef>Enabled</th>
          <td
            mat-cell
            *matCellDef="let element"
            [style.color]="element.enabledColor"
          >
            {{ element.enabled }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="element-row"
          (click)="rowClicked(row)"
        ></tr>
      </table>
      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <mat-paginator
        #paginator
        [length]="dataSource?.count"
        [pageSize]="this.paginator.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
  </div>
</div>
