import { ChangeDetectorRef, Component } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { IsLoadingService } from '@service-work/is-loading';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AuthService } from './auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  mobileQuery: MediaQueryList;
  fillerNav = Array.from({ length: 50 }, (_, i) => `Nav Item ${i + 1}`);

  isLoading$?: Observable<boolean>;
  isLoggedIn$?: Observable<boolean>;
  //wheelLoader: Observable<boolean> | undefined;

  email?: string;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private isLoadingService: IsLoadingService,
    private router: Router,
    private authService: AuthService,
    private snackBar: MatSnackBar) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit() {
    // Show Wheel Loader
    //this.wheelLoader = this.wheelLoadingService.getLoading()

    this.isLoggedIn$ = this.authService.loggedIn$;

    this.isLoggedIn$.subscribe(
      (result) => {
        if (result == true) {
          let currentUserStr = localStorage.getItem('seedorinaCurrentUser')
          let currentUser: any;

          if (currentUserStr) {
            currentUser = JSON.parse(currentUserStr)
            this.email = currentUser.email;
          } else this.snackBar.open("Ups, login failed ", "Ok")
        }
      })

    this.isLoading$ = this.isLoadingService.isLoading$();

    this.router.events
      .pipe(
        filter(
          (event) =>
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        )
      )
      .subscribe((event) => {
        // If it's the start of navigation, `add()` a loading indicator
        if (event instanceof NavigationStart) {
          this.isLoadingService.add();
          return;
        }

        // Else navigation has ended, so `remove()` a loading indicator
        this.isLoadingService.remove();
      });
  }

  onLogout() {
    this.authService.logout();
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
}
