<div class="container">
  <section>
    <div class="title-list">
      <div class="button-back">
        <button mat-icon-button aria-label="back" (click)="backClicked()">
          <mat-icon color="primary">arrow_back_ios</mat-icon>Products
        </button>
      </div>
      <div>
        <h1 *ngIf="!product" class="mat-headline">New Product</h1>
        <h1 *ngIf="product" class="mat-headline">Update Product</h1>
      </div>
    </div>
  </section>

  <form [formGroup]="profileForm" (ngSubmit)="onSubmit()">
    <!-- Product Info -->
    <mat-card class="card prod-card">
      <mat-card-content>
        <div class="card-content-container">
          <!-- Name -->
          <mat-form-field>
            <input matInput placeholder="Name" formControlName="name" />
            <mat-error *ngIf="f.name.errors && f.name.errors.required">
              Name is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>

          <!-- Description -->
          <mat-form-field>
            <input
              matInput
              placeholder="Description"
              formControlName="description"
            />
            <mat-error
              *ngIf="f.description.errors && f.description.errors.required"
            >
              Description is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>

          <!-- Price -->
          <mat-form-field>
            <input
              matInput
              placeholder="Price"
              type="number"
              formControlName="price"
            />
            <span matPrefix>$&nbsp;</span>
          </mat-form-field>

          <!-- Vendor -->
          <mat-form-field>
            <input
              type="text"
              placeholder="Pick a Merchant"
              aria-label="Merchant"
              matInput
              formControlName="vendor"
              [matAutocomplete]="autoVendor"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoVendor="matAutocomplete"
              [displayWith]="utilDetailService.displayName"
            >
              <mat-option
                *ngFor="let vendor of filteredVendors | async"
                [value]="vendor"
              >
                {{ vendor.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.vendor.errors && f.vendor.errors.required">
              Merchant is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>

          <!-- Category -->
          <mat-form-field>
            <input
              type="text"
              placeholder="Pick a Category"
              aria-label="Category"
              matInput
              formControlName="category"
              [matAutocomplete]="autoCategory"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoCategory="matAutocomplete"
              [displayWith]="utilDetailService.displayName"
              (optionSelected)="selectedCategoryEvent($event.option.value)"
            >
              <mat-option
                *ngFor="let category of filteredCategories | async"
                [value]="category"
              >
                {{ category.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="f.category.errors && f.category.errors.required">
              Category is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>

          <!-- Sub Category -->
          <mat-form-field>
            <input
              type="text"
              placeholder="Pick a Sub-category"
              aria-label="SubCategory"
              matInput
              formControlName="subCategory"
              [matAutocomplete]="autoSubcategory"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #autoSubcategory="matAutocomplete"
              [displayWith]="utilDetailService.displayName"
            >
              <mat-option
                *ngFor="let subcategory of filteredSubcategory | async"
                [value]="subcategory"
              >
                {{ subcategory.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error
              *ngIf="f.subCategory.errors && f.subCategory.errors.required"
            >
              Sub-category is <strong>mandatory</strong>
            </mat-error>
          </mat-form-field>

          <!-- Enabled -->
          <div class="mat-slide-toggle">
            <mat-slide-toggle
              class="enabled-product-toogle sm"
              formControlName="enabled"
              color="primary"
              >Enabled</mat-slide-toggle
            >
          </div>

          <!-- Image button-->
          <div>
            <button
              mat-button
              aria-label="image"
              type="button"
              class="img-button"
              (click)="fileUpload.click()"
            >
              <mat-icon color="primary">image</mat-icon>Select image
            </button>
            <input
              type="file"
              #fileUpload
              id="fileUpload"
              name="fileUpload"
              accept="image/*"
              style="display: none"
              (change)="showPreview($event)"
            />
          </div>
        </div>
      </mat-card-content>
      <!-- Image Preview -->
      <img
        *ngIf="imageURL && imageURL !== ''"
        [src]="imageURL"
        class="imagePreview"
      />
    </mat-card>
    <!-- Submit Button -->
    <div>
      <button
        type="submit"
        style="width: 100%"
        [disabled]="!profileForm.valid || (isLoading$ | async)"
        mat-raised-button
        color="accent"
        class="md-button-right prod-submit-button"
      >
        <div *ngIf="!product">Create</div>
        <div *ngIf="product">Update</div>
      </button>
    </div>
  </form>
</div>
