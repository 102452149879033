import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SeedDTO } from '../models/seeds';
import { SeedsService } from '../services/seeds.service';

@Injectable()
export class SeedResolver implements Resolve<any> {
    constructor(private seedsService: SeedsService) { }

    public resolve(route: ActivatedRouteSnapshot): Observable<SeedDTO> | undefined {
        let id: number = route.queryParams.Id as unknown as number
        if (id)
            return this.seedsService.getById(id);
        else return undefined
    }
}