import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { SeedDTO, SeedsListDTO } from "../models/seeds";
import { SeedsService } from "./seeds.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class SeedsDataSource implements DataSource<SeedDTO>{

    private dataSubject = new BehaviorSubject<SeedDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private seedsService: SeedsService,
        private snackBar: MatSnackBar) { }

    loadData(pageSize: number,
        head: number, name: string) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.seedsService.getList(pageSize, head, name).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as SeedsListDTO
                this.count = listDTO.totalSeeds ? listDTO.totalSeeds : 0;
                this.dataSubject.next(listDTO.seeds ? listDTO.seeds : []);
            },
            err => this.snackBar.open("Error retrieving seeds from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<SeedDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
}
