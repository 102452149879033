import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';
import { VendorDTO } from 'src/app/models/vendors';
import { OrdersDataSource } from 'src/app/services/orders.datasource';
import { OrdersService } from 'src/app/services/orders.service';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';
import { UtilListService } from 'src/app/services/shared/util-list.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {


  /* Loading */
  loading: boolean = true;

  /* Table Attributes */
  dataSource!: OrdersDataSource;
  displayedColumns: string[] = ['id', 'user', 'createdAt', 'vendor', 'status', 'product', 'quantity', "price"];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  /* Filters Form */
  profileForm: FormGroup;
  filteredVendors: Observable<VendorDTO[] | undefined> | undefined;
  vendors: VendorDTO[] | undefined;

  // Dates
  today: Date;
  firstDayOfTheMonth: Date;

  constructor(private ordersService: OrdersService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public utilListService: UtilListService,
    private location: Location) {

    this.today = new Date();
    this.firstDayOfTheMonth = new Date(this.today.getFullYear(), this.today.getMonth(), 1);

    this.profileForm = fb.group({
      start: [this.firstDayOfTheMonth],
      end: [this.today],
      vendor: [null]
    });
  }

  ngOnInit(): void {

    this.autocompletesValueChangeHandler()

    this.route.queryParams.subscribe(params => {
      this.loadURLParams();
      this.initTable();
    });
  }

  private initTable() {
    this.dataSource = new OrdersDataSource(this.ordersService, this.snackBar);
    this.loadTable();
    this.dataSource.loading$.subscribe(result => { this.loading = result; });
  }

  private loadURLParams() {
    this.utilListService.initPaginator(this.paginator, this.route);
    this.profileForm.controls.start.setValue(this.route.snapshot.params.start != undefined ? this.fromStringToDate(this.route.snapshot.params.start) : this.firstDayOfTheMonth );
    this.profileForm.controls.end.setValue(this.route.snapshot.params.end != undefined ? this.fromStringToDate(this.route.snapshot.params.end) : this.today);
    this.profileForm.controls.vendor.setValue(this.utilListService._filterModelById(this.route.snapshot.params.vendorId, this.vendors));
  }

  autocompletesValueChangeHandler() {
    this.vendors = this.route.snapshot.data.vendors as VendorDTO[];
    this.filteredVendors = this.profileForm.controls.vendor.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilListService._filterModelByName(name, this.vendors) : this.vendors?.slice())
      );
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadTable())
      )
      .subscribe();
  }

  applyFilter(): void {
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  loadTable() {
    this.dataSource?.loadData(
      this.paginator.pageSize,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.fromDateToString(this.profileForm.value.start),
      this.fromDateToString(this.profileForm.value.end),
      this.profileForm.value.vendor ? this.profileForm.value.vendor.id : null);

    this.updateURLParams();
  }

  updateURLParams() {
    this.router.navigate(
      [
        {
          pageSize: (this.paginator.pageSize ? this.paginator.pageSize : ''),
          pageIndex: (this.paginator.pageIndex ? this.paginator.pageIndex : ''),
          start: (this.profileForm.value.start ? this.fromDateToString(this.profileForm.value.start) : ''),
          end: (this.profileForm.value.end ? this.fromDateToString(this.profileForm.value.end) : ''),
          vendorId: (this.profileForm.value.vendor ? this.profileForm.value.vendor.id : ''),
        }
      ],
      {
        relativeTo: this.route,
        replaceUrl: true
      }
    );
  }

  fromDateToString(date: Date): string {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
  }

  fromStringToDate(date: string): Date {
    return new Date(date);
  }

  rowClicked(row: { id: string; }) {
    this.router.navigate(['/order'], { queryParams: { Id: row.id } });
  }

}
