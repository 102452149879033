import { Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { CategoryDTO, ProductDTO, SubcategoryDTO } from 'src/app/models/products';
import { ProductsService } from 'src/app/services/products.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { VendorDTO } from 'src/app/models/vendors';
import { catchError, map, startWith } from 'rxjs/operators';
import { idSelectedValidator, UtilDetailService } from 'src/app/services/shared/util-detail.service';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { of } from 'rxjs';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {

  /* Form */
  product?: ProductDTO;
  profileForm!: FormGroup;
  filteredVendors: Observable<VendorDTO[] | undefined> | undefined;
  filteredCategories: Observable<CategoryDTO[] | undefined> | undefined;
  filteredSubcategory: Observable<SubcategoryDTO[] | undefined> | undefined;
  vendors?: VendorDTO[];
  categories?: CategoryDTO[];
  subcategories?: SubcategoryDTO[];
  subcategoriesOfSelectedCategory?: SubcategoryDTO[];
  imageURL?: string;

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;
  isLoadingSeeds?: boolean;

  @ViewChild("fileUpload", { static: false }) fileUpload?: ElementRef;
  progress?: number;

  constructor(private fb: FormBuilder,
    private productsService: ProductsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    public utilDetailService: UtilDetailService) {
  }

  get f() { return this.profileForm.controls; }

  ngOnInit(): void {
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.product = this.route.snapshot.data.product as ProductDTO;
    this.vendors = this.route.snapshot.data.vendors as VendorDTO[];
    this.categories = this.route.snapshot.data.categories as CategoryDTO[];
    this.subcategories = this.route.snapshot.data.subcategories as SubcategoryDTO[];
    this.subcategoriesOfSelectedCategory = this.subcategories;
    this.initializeForm();
  }

  async initializeForm() {
    this.profileForm = this.fb.group({
      id: [this.product ? this.product.id : null],
      name: [this.product ? this.product.name : null, Validators.required],
      description: [this.product ? this.product.description : null, Validators.required],
      price: [this.product ? this.product.price : null],
      vendor: [this.product ? { id: this.product.vendorId, name: this.product.vendor } : null, Validators.required],
      category: [this.product ? { id: this.product.categoryId, name: this.product.category } : null, Validators.required],
      subCategory: [(this.product && this.product.subCategoryId) ? { id: this.product.subCategoryId, name: this.product.subCategory } : { value: null, disabled: true }],
      enabled: [this.product ? this.product.enabled : true, Validators.required],
      image: [null],
    });

    this.imageURL = this.product && this.product.image ? this.product.image + `?timeStamp=${Date.now()}` : undefined

    this.autocompletesValueChangeHandler();
  }

  private autocompletesValueChangeHandler() {
    this.filteredVendors = this.profileForm.controls.vendor.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilDetailService._filterModelByName(name, this.vendors) : this.vendors?.slice())
      );

    this.filteredCategories = this.profileForm.controls.category.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilDetailService._filterModelByName(name, this.categories) : this.categories?.slice())
      );
  }

  private subCategoryFilterValueChangeHandler() {
    this.filteredSubcategory = this.profileForm.controls.subCategory.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this.utilDetailService._filterModelByName(name, this.subcategoriesOfSelectedCategory) : this.subcategoriesOfSelectedCategory?.slice())
      );
  }

  selectedCategoryEvent(selectedCategory: CategoryDTO) {
    if (this.subcategories && this.subcategories.length > 0 && selectedCategory) {
      this.subcategoriesOfSelectedCategory = this.subcategories.filter(option => option.categoryId == selectedCategory.id);
      this.profileForm.controls.subCategory.setValue("")
      this.subCategoryFilterValueChangeHandler();
    }

    // Enable or disable SubCategory select
    if (this.subcategoriesOfSelectedCategory && this.subcategoriesOfSelectedCategory.length > 0 && selectedCategory) {
      this.profileForm.controls.subCategory.enable()
    }
    else this.profileForm.controls.subCategory.disable()
  }

  // Show Image Preview
  showPreview(event: any) {
    if (event && event.target && this.profileForm) {
      let target = (event.target as HTMLInputElement)
      const file = target && target.files ? target.files[0] : new Blob;
      this.profileForm.patchValue({
        image: file
      });

      let imageControl = this.profileForm.get('image');
      if (imageControl)
        imageControl.updateValueAndValidity()

      // File Preview
      const reader = new FileReader();
      reader.onload = () => {
        this.imageURL = reader.result as string;
      }
      reader.readAsDataURL(file)
    }
  }

  // Create or Update Product
  public onSubmit() {

    this.isLoadingService.add();
    let isModelNew = true;

    // Create DTO
    let productDTO: ProductDTO = {
      name: this.profileForm.value.name,
      description: this.profileForm.value.description,
      price: this.profileForm.value.price ? this.profileForm.value.price : 0,
      vendorId: this.profileForm.value.vendor.id,
      categoryId: this.profileForm.value.category.id,
      subCategoryId: this.profileForm.value.subCategory ? this.profileForm.value.subCategory.id : null,
      enabled: this.profileForm.value.enabled,
    }
    if (this.product) {
      productDTO.id = this.profileForm.value.id
      isModelNew = false
    }

    const formData = new FormData();
    if (this.profileForm.controls.image.value)
      formData.append('file', this.profileForm.controls.image.value, 'file');
    formData.append('product', JSON.stringify(productDTO));

    this.productsService.modify(isModelNew, formData).pipe().
      subscribe(async (result: ProductDTO) => {
        if (result != null && result.id) {
          this.product = result;
          this.location.replaceState('/product?Id=' + this.product.id);
          this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Product');
          this.location.back()
        } else {
          this.snackBar.open('Error creating or updating Product.', 'Ok');
          this.product = undefined;
        }
        this.isLoadingService.remove();
      },
        err => { }
      );
  }

  backClicked() {
    this.location.back();
  }
}
