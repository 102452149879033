import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { RobotsDataSource } from 'src/app/services/robots.datasource';
import { RobotsService } from 'src/app/services/robots.service';
import { UtilListService } from 'src/app/services/shared/util-list.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-robot-list',
  templateUrl: './robot-list.component.html',
  styleUrls: ['./robot-list.component.scss']
})
export class RobotListComponent implements OnInit {

  /* Loading */
  loading: boolean = true;

  /* Table Attributes */
  dataSource!: RobotsDataSource;
  displayedColumns: string[] = ['serial', 'createdAt', 'updatedAt', 'enabled'];
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;

  /* Filters Form */
  profileForm: FormGroup;

  constructor(private robotsService: RobotsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private utilListService: UtilListService,
    private location: Location) {
    this.profileForm = fb.group({
      serial: [null],
      enabled: [true],
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.loadURLParams();
      this.initTable();
    });
  }

  private loadURLParams() {
    if (!this.route.snapshot.params.serial)
      this.location.replaceState('/robots');

    this.utilListService.initPaginator(this.paginator, this.route);
    this.profileForm.controls.serial.setValue(this.route.snapshot.params.serial ? this.route.snapshot.params.serial : '');
    this.profileForm.controls.enabled.setValue(this.route.snapshot.params.enable != undefined ? this.utilListService.stringToBoolean(this.route.snapshot.params.enable) : true);
  }

  private initTable() {
    this.dataSource = new RobotsDataSource(this.robotsService, this.snackBar);
    this.loadTable();
    this.dataSource.loading$.subscribe(result => { this.loading = result; });
  }

  ngAfterViewInit() {
    this.paginator.page
      .pipe(
        tap(() => this.loadTable())
      )
      .subscribe();
  }

  applyFilter(): void {
    this.paginator.pageIndex = 0;
    this.loadTable();
  }

  loadTable() {
    this.dataSource?.loadData(
      this.paginator.pageSize,
      this.paginator.pageIndex * this.paginator.pageSize,
      this.profileForm.value.serial, this.profileForm.value.enabled);

    this.updateURLParams();
  }

  updateURLParams() {
    this.router.navigate(
      [
        {
          pageSize: (this.paginator.pageSize ? this.paginator.pageSize : ''),
          pageIndex: (this.paginator.pageIndex ? this.paginator.pageIndex : ''),
          serial: (this.profileForm.value.serial ? this.profileForm.value.serial : ''),
          enable: (this.profileForm.value.enabled ? this.profileForm.value.enabled : ''),
        }
      ],
      {
        relativeTo: this.route,
        replaceUrl: true
      }
    );
  }

  rowClicked(row: { robotId: string; }) {
    this.router.navigate(['/robot'], { queryParams: { Id: row.robotId } });
  }

}