import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { RobotDTO } from '../models/robots';
import { RobotsService } from '../services/robots.service';

@Injectable({
  providedIn: 'root'
})
export class RobotsResolver implements Resolve<any> {
  constructor(private robotsService: RobotsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<RobotDTO[]> {
    return this.robotsService.getListAutocomplet();
  }
}
