<mat-card class="card">
  <form *ngIf="robotFormGroup" [formGroup]="getFormGroup()" class="robot-form">
    <!-- Robot -->
    <mat-form-field>
      <input
        type="text"
        placeholder="Pick a robot"
        aria-label="Robot"
        matInput
        formControlName="serial"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #auto="matAutocomplete"
        [displayWith]="displayRobotName"
      >
        <mat-option
          *ngFor="let robot of filteredRobots | async"
          [value]="robot"
        >
          {{ robot.serial }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <!-- Token -->
    <mat-form-field>
      <input matInput placeholder="Token" formControlName="token" />
    </mat-form-field>

    <!-- Date Range -->
    <mat-form-field>
      <mat-label>Enter a date range</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input
          matStartDate
          formControlName="fromDate"
          placeholder="Start date"
        />
        <input matEndDate formControlName="toDate" placeholder="End date" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error
        *ngIf="getFormGroup().controls.fromDate.hasError('matStartDateInvalid')"
        >Invalid start date</mat-error
      >
      <mat-error
        *ngIf="getFormGroup().controls.toDate.hasError('matEndDateInvalid')"
        >Invalid end date</mat-error
      >
    </mat-form-field>

    <!-- Enabled -->
    <div class="mat-slide-toggle">
      <mat-slide-toggle formControlName="enabled" color="primary"
        >Enabled</mat-slide-toggle
      >
    </div>

    <!-- Btn Eliminar -->
    <button
      *ngIf="isRobotSelectNew()"
      mat-icon-button
      aria-label="clear"
      (click)="deleteRobot(); $event.preventDefault()"
      class="clear-icon"
      color="warn"
    >
      <mat-icon>clear</mat-icon>
    </button>
  </form>
</mat-card>
