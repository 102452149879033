import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { SeedDTO, SeedsListDTO } from '../models/seeds';


@Injectable({
  providedIn: 'root'
})
export class SeedsService {

  constructor(private http: HttpClient) { }

  getList(pageSize: number, head: number, name: string): Observable<SeedsListDTO> {

    let httpParams = new HttpParams()
    httpParams = name ? httpParams.append('name', name) : httpParams;

    const requestOptions = {
      params: httpParams
    };

    return this.http.get<SeedsListDTO>(environment.serverAPI + 'seed/' + pageSize + '/' + head, requestOptions);
  }

  getListAutocomplet(): Observable<SeedDTO[]> {
    return this.http.get<SeedDTO[]>(environment.serverAPI + 'seed/');
  }

  getById(id: number): Observable<SeedDTO> {
    return this.http.get<SeedDTO>(environment.serverAPI + 'seed/' + id);
  }

  modify(seed: SeedDTO): Observable<SeedDTO> {
    if (seed.id)
      return this.update(seed);
    else
      return this.create(seed)
  }

  create(seed: SeedDTO): Observable<SeedDTO> {
    return this.http.post<SeedDTO>(environment.serverAPI + 'seed', seed);
  }

  update(seed: SeedDTO): Observable<SeedDTO> {
    return this.http.put<SeedDTO>(environment.serverAPI + 'seed', seed);
  }

}
