import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable, BehaviorSubject, of } from "rxjs";
import { OrderDTO, OrderListDTO } from "../models/orders";
import { OrdersService } from "./orders.service";
import { catchError, finalize } from "rxjs/operators";
import { MatSnackBar } from '@angular/material/snack-bar';

export class OrdersDataSource implements DataSource<OrderDTO>{

    private dataSubject = new BehaviorSubject<OrderDTO[]>([]);

    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading$ = this.loadingSubject.asObservable();

    public count: number = 0;

    constructor(private ordersService: OrdersService,
        private snackBar: MatSnackBar) { }


    loadData(pageSize: number,
        head: number,
        from: string,
        to: string,
        vendorId: string) {

        this.loadingSubject.next(true);
        this.dataSubject.next([]);

        this.ordersService.getList(pageSize, head, from, to, vendorId).pipe(
            catchError(err => of([])),
            finalize(() => this.loadingSubject.next(false))
        ).subscribe(
            (response: any) => {
                let listDTO = response as OrderListDTO
                this.ordersService.setStatusColor(listDTO);
                this.count = listDTO.totalOrders ? listDTO.totalOrders : 0;
                this.dataSubject.next(listDTO.orders ? listDTO.orders : []);
            },
            err => this.snackBar.open("Error retrieving orders from server.", 'Ok')
        );
    }

    connect(collectionViewer: CollectionViewer): Observable<OrderDTO[] | readonly any[]> {
        return this.dataSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.dataSubject.complete();
        this.loadingSubject.complete();
    }
}
