import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { RobotDTO } from '../models/robots';
import { RobotsService } from '../services/robots.service';

@Injectable()
export class RobotResolver implements Resolve<any> {
    constructor(private robotsService: RobotsService) { }

    public resolve(route: ActivatedRouteSnapshot): Observable<RobotDTO> | undefined {
        let id: number = route.queryParams.Id as unknown as number
        if (id)
            return this.robotsService.getById(id);
        else return undefined
    }
}