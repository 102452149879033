import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { OrderListDTO, OrderDTO, OrderStatus } from '../models/orders';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  getList(pageSize: number, head: number, from: string, to: string, vendorId: string): Observable<OrderListDTO> {

    let httpParams = new HttpParams()
    httpParams = from ? httpParams.append('fromDate', from) : httpParams;
    httpParams = httpParams.append('toDate', to);
    if (vendorId)
      httpParams = httpParams.append('vendorId', vendorId);

    const requestOptions = {
      params: httpParams
    };

    return this.http.get<OrderListDTO>(environment.serverAPI + 'order/' + pageSize + '/' + head, requestOptions);
  }

  getById(id: number): Observable<OrderDTO> {
    return this.http.get<OrderDTO>(environment.serverAPI + 'order/' + id);
  }

  modify(order: OrderDTO): Observable<OrderDTO> {
    if (order.id)
      return this.update(order);
    else
      return this.create(order)
  }

  create(order: OrderDTO): Observable<OrderDTO> {
    return this.http.post<OrderDTO>(environment.serverAPI + 'order', order);
  }

  update(order: OrderDTO): Observable<OrderDTO> {
    return this.http.put<OrderDTO>(environment.serverAPI + 'order', order);
  }

  setStatusColor(result?: OrderListDTO) {
    if (result && result.orders) {
      result.orders.forEach(order => {
        this.setOrderStatus(order);
      });
    }
  }

  public setOrderStatus(order?: OrderDTO) {
    if (order) {
      switch (order.status) {
        case OrderStatus.pending:
          order.statusColor = "#f0ad4e";
          break;
        case OrderStatus.pendingPayment:
          order.statusColor = "#0275d8";
          break;
        case OrderStatus.approved:
          order.statusColor = "#bb93c9";
          break;
        case OrderStatus.delivered:
          order.statusColor = "#008296";
          break;
        case OrderStatus.refunded:
          order.statusColor = "#d9534f";
          break;
        case OrderStatus.cancel:
          order.statusColor = "#292b2c";
          break;
        default:
          break;
      }

      if (order && order.quantity && order.product && order.product.price) {
        order.total = order.product.price * order.quantity;
      }
    }
  }
}
