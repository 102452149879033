import { ProductDTO } from "./products";

export class OrderDTO {
    id?: number;
    userId?: number;
    status?: string;
    statusColor?: string;
    user?: UserDTO;
    product?: ProductDTO;
    productId?: number;
    quantity?: number;
    createdAt?: Date;
    updatedAt?: Date;
    total?: number;
}

export class OrderListDTO {
    totalOrders?: number;
    orders?: OrderDTO[];
}

export class UserDTO {
    id?: number;
    email?: string;
    password?: string;
    phone?: string;
}


export enum OrderStatus {
  pending = "Nuevo",
  pendingPayment = "Pendiente Pago",
  approved = "Pago",
  delivered = "Entregado",
  refunded = "Devuelto",
  cancel = "Cancelado",
}