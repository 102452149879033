import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SubcategoryDTO } from '../models/products';
import { ProductsService } from '../services/products.service';

@Injectable({
  providedIn: 'root'
})
export class SubcategoriesResolver implements Resolve<any> {
  constructor(private productsService: ProductsService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<SubcategoryDTO[]> {
    return this.productsService.getSubcategories();
  }
}
