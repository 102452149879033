import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyDTO } from '../models/companies';
import { CompaniesService } from '../services/companies.service';

@Injectable()
export class CompanyResolver implements Resolve<any> {
    constructor(private companiesService: CompaniesService) { }

    public resolve(route: ActivatedRouteSnapshot): Observable<CompanyDTO> | undefined {
        let id: number = route.queryParams.Id as unknown as number
        if (id)
            return this.companiesService.getById(id);
        else return undefined
    }
}