import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { CategoryDTO, ProductDTO, ProductListDTO, SubcategoryDTO } from '../models/products';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getList(pageSize: number, head: number, name: string, enabled: boolean, vendorId: number, categoryId: number, subCategoryId: number): Observable<ProductListDTO> {

    let httpParams = new HttpParams()
    httpParams = name ? httpParams.append('name', name) : httpParams;
    httpParams = httpParams.append('enabled', enabled.toString());
    httpParams = vendorId ? httpParams.append('vendorId', vendorId.toString()) : httpParams;
    httpParams = categoryId ? httpParams.append('categoryId', categoryId.toString()) : httpParams;
    httpParams = subCategoryId ? httpParams.append('subCategoryId', subCategoryId.toString()) : httpParams;

    const requestOptions = {
      params: httpParams
    };

    return this.http.get<ProductListDTO>(environment.serverAPI + 'product/' + pageSize + '/' + head, requestOptions);
  }

  getListAutocomplet(): Observable<ProductDTO[]> {
    return this.http.get<ProductDTO[]>(environment.serverAPI + 'product/');
  }

  getById(id: number): Observable<ProductDTO> {
    return this.http.get<ProductDTO>(environment.serverAPI + 'product/' + id);
  }

  modify(isModelNew: boolean, formData: any): Observable<ProductDTO> {
    if (isModelNew)
      return this.create(formData)
    else
      return this.update(formData);
  }

  create(formData: any): Observable<ProductDTO> {
    return this.http.post<ProductDTO>(environment.serverAPI + 'product/photo', formData);
  }

  update(formData: any): Observable<ProductDTO> {
    return this.http.put<ProductDTO>(environment.serverAPI + 'product/photo', formData);
  }


  getCategories(): Observable<CategoryDTO[]> {
    return this.http.get<CategoryDTO[]>(environment.serverAPI + 'category');
  }

  getSubcategories(): Observable<SubcategoryDTO[]> {
    return this.http.get<SubcategoryDTO[]>(environment.serverAPI + 'subcategory');
  }
}
