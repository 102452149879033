import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ProgramDTO } from 'src/app/models/programs';
import { ProgramsService } from 'src/app/services/programs.service';
import { Location } from '@angular/common';
import { IsLoadingService } from '@service-work/is-loading';
import { Observable } from 'rxjs';
import { SeedDTO } from 'src/app/models/seeds';
import { SeedsService } from 'src/app/services/seeds.service';
import { map, startWith } from 'rxjs/operators';
import { UtilDetailService } from 'src/app/services/shared/util-detail.service';
import { ValidateId } from 'src/app/helpers/id.validator';

@Component({
  selector: 'app-program-details',
  templateUrl: './program-details.component.html',
  styleUrls: ['./program-details.component.scss']
})
export class ProgramDetailsComponent implements OnInit {

  /* Form */
  program?: ProgramDTO;
  profileForm!: FormGroup;
  filteredSeeds: Observable<SeedDTO[] | undefined> | undefined;
  seeds?: SeedDTO[];

  /* Dates */
  today: Date = new Date();

  /* Loadings */
  isLoading$?: Observable<boolean>;
  isLoadingSeeds?: boolean;

  constructor(private fb: FormBuilder,
    private programsService: ProgramsService,
    private seedsService: SeedsService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private location: Location,
    private isLoadingService: IsLoadingService,
    private utilDetailService: UtilDetailService) {

  }

  get f() { return this.profileForm.controls; }

  ngOnInit(): void {
    this.isLoading$ = this.isLoadingService.isLoading$();
    this.program = this.route.snapshot.data.program as ProgramDTO;
    this.initializeForm();
  }

  async initializeForm() {
    this.profileForm = this.fb.group({
      id: [this.program ? this.program.programId : null],
      name: [this.program ? this.program.name : null, Validators.required],
      code: [this.program ? this.program.code : null, Validators.required],
      seed: [this.program ? this.program.seeds[0] : null, [Validators.required, ValidateId()]],
      version: [this.program ? { value: this.program.version, disabled: true } : null],
      createdAt: [this.program ? { value: this.program.createdAt?.toLocaleString().substring(0, 16), disabled: true } : null],
      updatedAt: [this.program ? { value: this.program.updatedAt?.toLocaleString().substring(0, 16), disabled: true } : null],
      enabled: [this.program ? this.program.enabled : true, Validators.required],
    });

    this.initializeSeedAutocomplete();
  }

  private async initializeSeedAutocomplete() {
    this.seedValueChangeHandler();
    this.getSeeds();
  }

  private getSeeds() {
    this.seedsService.getListAutocomplet().toPromise().then(result => {
      if (result) {
        this.seeds = result;
        this.isLoadingSeeds = false;
      } else { this.snackBar.open('Error fetching seeds from server.', 'Ok'); }
    });
  }

  private seedValueChangeHandler() {
    this.filteredSeeds = this.profileForm.controls.seed.valueChanges
      .pipe(
        startWith(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filterSeeds(name) : this.seeds?.slice())
      );
  }

  displaySeedName(seed?: SeedDTO): string {
    return seed ? seed.name : "";
  }

  private _filterSeeds(value: string): SeedDTO[] {
    const filterValue = value.toLowerCase();

    if (this.seeds)
      return this.seeds.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    else return []
  }

  // Create or Update Program
  public onSubmit() {
    this.isLoadingService.add();
    let isModelNew = true;

    // Create DTO
    let programDTO: ProgramDTO = {
      name: this.profileForm.value.name,
      code: this.profileForm.value.code,
      seeds: [{ id: this.profileForm.value.seed.id, name: this.profileForm.value.seed.name }],
      enabled: this.profileForm.value.enabled,
    }
    if (this.program) {
      programDTO.programId = this.profileForm.value.id
      isModelNew = false
    }

    // Create or Update Program
    this.programsService.modify(programDTO).subscribe(async (result: ProgramDTO) => {
      if (result != null && result.programId) {
        this.program = result;
        this.location.replaceState('/program?Id=' + this.program.programId);
        this.utilDetailService.showDetailSuccessMsg(isModelNew, 'Program');
        this.location.back()
      } else {
        this.snackBar.open('Error creating or updating Program.', 'Ok');
        this.program = undefined;
      }
      this.isLoadingService.remove();
    },
      err => { }
    );
  }

  backClicked() {
    this.location.back();
  }
}
