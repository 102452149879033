import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment.prod';
import { CompanyDTO, CompanyListDTO } from '../models/companies';


@Injectable({
  providedIn: 'root'
})
export class CompaniesService {

  constructor(private http: HttpClient) { }

  getList(pageSize: number, head: number, name: string): Observable<CompanyListDTO> {

    let httpParams = new HttpParams()
    httpParams = name ? httpParams.append('name', name) : httpParams;

    const requestOptions = {
      params: httpParams
    };

    return this.http.get<CompanyListDTO>(environment.serverAPI + 'company/' + pageSize + '/' + head, requestOptions);
  }

  getById(id: number): Observable<CompanyDTO> {
    return this.http.get<CompanyDTO>(environment.serverAPI + 'company/' + id);
  }

  modify(company: CompanyDTO): Observable<CompanyDTO> {
    if (company.companyId)
      return this.update(company);
    else
      return this.create(company)
  }

  create(company: CompanyDTO): Observable<CompanyDTO> {
    return this.http.post<CompanyDTO>(environment.serverAPI + 'company', company);
  }

  update(company: CompanyDTO): Observable<CompanyDTO> {
    return this.http.put<CompanyDTO>(environment.serverAPI + 'company', company);
  }
  
}
