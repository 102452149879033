import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { VendorDTO } from '../models/vendors';
import { VendorsService } from '../services/vendors.service';

@Injectable({
  providedIn: 'root'
})
export class VendorsResolver implements Resolve<any> {
  constructor(private vendorsService: VendorsService) { }

  public resolve(route: ActivatedRouteSnapshot): Observable<VendorDTO[]> {
    return this.vendorsService.getListAutocomplet();
  }
}
