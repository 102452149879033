import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-log-in',
  templateUrl: './log-in.component.html',
  styleUrls: ['./log-in.component.scss']
})
export class LogInComponent implements OnInit {

  form: FormGroup = new FormGroup({
    username: new FormControl(''),
    password: new FormControl(''),
  });

  loading: boolean = true;
  returnUrl: string = '';

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) { }

  ngOnInit() {
    // reset login status
    this.authService.logout();
    setTimeout(() => {
      this.loading = false;
    });
  }

  submit() {
    this.loading = true;

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    this.authService.login(this.form.value.username, this.form.value.password)
      .subscribe(
        data => {
          //this.router.navigate([this.returnUrl]);
          this.router.navigateByUrl(this.returnUrl);
        },
        error => {
          this.loading = false;
        });
  }

}
