import { Injectable } from '@angular/core';
import { ProgramDTO } from '../models/programs';
import { ProgramsService } from '../services/programs.service';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgramResolver implements Resolve<any> {
  constructor(private programsService: ProgramsService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ProgramDTO> | undefined {
    let id: number = route.queryParams.Id as unknown as number
    if (id)
      return this.programsService.getById(id);
    else return undefined
  }
}

