<div class="container">
  <div class="title-list">
    <div><h1 class="mat-display-1 h1-list">Orders</h1></div>
    <div
      class="button-add"
      [routerLinkActive]="['active']"
      [routerLink]="['/order']"
    ></div>
  </div>
  <div class="mat-elevation-z2">
    <!-- Filters -->
    <Form
      #filters
      [formGroup]="profileForm"
      class="table-header-filter-container"
    >
      <!-- Date Range -->
      <mat-form-field class="table-header-filter">
        <mat-label>Buscar por fecha</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error
          *ngIf="profileForm.controls.start.hasError('matStartDateInvalid')"
          >Invalid start date</mat-error
        >
        <mat-error
          *ngIf="profileForm.controls.end.hasError('matEndDateInvalid')"
          >Invalid end date</mat-error
        >
      </mat-form-field>

      <!-- Vendor -->
      <mat-form-field class="table-header-filter">
        <input
          type="text"
          placeholder="Pick a Merchant"
          aria-label="Merchant"
          matInput
          formControlName="vendor"
          [matAutocomplete]="autoVendor"
        />
        <mat-autocomplete
          autoActiveFirstOption
          #autoVendor="matAutocomplete"
          [displayWith]="utilListService.displayName"
        >
          <mat-option
            *ngFor="let vendor of filteredVendors | async"
            [value]="vendor"
          >
            {{ vendor.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <!-- Search button-->
      <div class="table-header-icon">
        <button
          mat-icon-button
          type="submit"
          [disabled]="!profileForm.valid"
          (click)="loadTable()"
          color="primary"
          aria-label="Filter"
        >
          <mat-icon>search</mat-icon>
        </button>
      </div>
    </Form>

    <!-- Table -->
    <div #table class="table-container">
      <table mat-table [dataSource]="dataSource">
        <!-- Id -->
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>

        <!-- User -->
        <ng-container matColumnDef="user">
          <th mat-header-cell *matHeaderCellDef>User</th>
          <td mat-cell *matCellDef="let element">
            {{ element.user.email }}
          </td>
        </ng-container>

        <!-- CreatedAt  -->
        <ng-container matColumnDef="createdAt" class="xxl">
          <th mat-header-cell *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let element">
            {{ element.createdAt | date: "h:mm a, d/M":"UTC -6" }}
          </td>
        </ng-container>

        <!-- Vendor -->
        <ng-container matColumnDef="vendor">
          <th mat-header-cell *matHeaderCellDef>Merchant</th>
          <td mat-cell *matCellDef="let element">
            {{ element.product.vendor }}
          </td>
        </ng-container>

        <!-- Status -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <mat-chip-list>
              <mat-chip
                [style.background-color]="element.statusColor"
                [style.color]="'#f7f7f7'"
                class="mat-chip"
              >
                {{ element.status }}
              </mat-chip>
            </mat-chip-list>
          </td>
        </ng-container>

        <!-- Product -->
        <ng-container matColumnDef="product">
          <th mat-header-cell *matHeaderCellDef>Product</th>
          <td mat-cell *matCellDef="let element">
            {{ element.product.name }}
          </td>
        </ng-container>

        <!-- Quantity -->
        <ng-container matColumnDef="quantity">
          <th mat-header-cell *matHeaderCellDef>Quantity</th>
          <td mat-cell *matCellDef="let element">
            {{ element.quantity }}
          </td>
        </ng-container>

        <!-- Total -->
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef>Total</th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.product.price > 0">
              ${{ element.total }}
            </div>
            <div *ngIf="element.product.price == 0">N/A</div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          class="element-row"
          (click)="rowClicked(row)"
        ></tr>
      </table>
      <div class="spinner-container" *ngIf="dataSource.loading$ | async">
        <mat-spinner [diameter]="50"></mat-spinner>
      </div>
      <mat-paginator
        #paginator
        [length]="dataSource?.count"
        [pageSize]="this.paginator.pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
      >
      </mat-paginator>
    </div>
  </div>
</div>
